<template>
  <div class="subsection__inner-text">
    <template v-if="isTextWithLinksDescription">
      <i18n :path="`userGuide.glossary.${termDescription}`" tag="span">
        <router-link
          v-for="{ keyPostfix, toPageName } in termAdditionalData"
          :key="keyPostfix"
          :to="{ name: toPageName }"
          class="subsection__link"
          target="_blank"
        >
          {{ $t(`userGuide.glossary.${keyPostfix}`) }}
        </router-link>
      </i18n>
    </template>
    <template v-else>
      <span v-for="description in termDescription" :key="description">
        {{ $t(`userGuide.glossary.${description}`) }}
        <br />
      </span>
    </template>
  </div>
</template>

<script>
import GlossaryTerm from '@/modules/dashboard/components/user-guide/articles/GlossaryTerm';

import { GLOSSARY_TERM_VALUE, GLOSSARY_DESCRIPTION_TYPE } from '@/modules/dashboard/api/user-guide';

const TERMS_CONFIG = {
  [GLOSSARY_TERM_VALUE.ACTIVE_INGREDIENT]: new GlossaryTerm({ description: ['activeIngredients'] }),
  [GLOSSARY_TERM_VALUE.ANOMALY]: new GlossaryTerm({ description: ['incoherentResults'] }),
  [GLOSSARY_TERM_VALUE.CAMPAIGN_MARKETINGS]: new GlossaryTerm({ description: ['wayToShare'] }),
  [GLOSSARY_TERM_VALUE.CLINIC_LOCATIONS]: new GlossaryTerm({ description: ['tagPatients'] }),
  [GLOSSARY_TERM_VALUE.COMMENTS]: new GlossaryTerm({ description: ['importantInformation'] }),
  [GLOSSARY_TERM_VALUE.CONCENTRATION]: new GlossaryTerm({ description: ['cosmeticLimit'] }),
  [GLOSSARY_TERM_VALUE.COPY_LINK]: new GlossaryTerm({ description: ['copyQuestionnaire'] }),
  [GLOSSARY_TERM_VALUE.CREATE_PATIENT]: new GlossaryTerm({
    type: GLOSSARY_DESCRIPTION_TYPE.TEXT_WITH_LINKS,
    description: 'createPatient',
    additionalData: [
      { keyPostfix: 'termsAndConditions', toPageName: 'Terms' },
      { keyPostfix: 'patientInformation', toPageName: 'PatientNotice' }
    ]
  }),
  [GLOSSARY_TERM_VALUE.CREATE_TREATMENT]: new GlossaryTerm({
    description: [
      'createTreatment',
      'treatmentCan',
      'madeByDoctor',
      'generateQuestionnaire',
      'generatePhotoAnalysis'
    ]
  }),
  [GLOSSARY_TERM_VALUE.DASHBOARD]: new GlossaryTerm({ description: ['pageWithList'] }),
  [GLOSSARY_TERM_VALUE.DIAGNOSTIC]: new GlossaryTerm({ description: ['quickOverview'] }),
  [GLOSSARY_TERM_VALUE.DYSFUNCTIONS]: new GlossaryTerm({
    description: [
      'problemPatientSkin',
      'oxidativeStress',
      'skinAppearance',
      'skinRedness',
      'skinDrynessDehydradation',
      'skinTexture',
      'skinOiliness',
      'skinPigmentation',
      'pimples'
    ]
  }),
  [GLOSSARY_TERM_VALUE.FORMULA]: new GlossaryTerm({
    description: ['activeIngredientsRecommended']
  }),
  [GLOSSARY_TERM_VALUE.FORMULATION]: new GlossaryTerm({ description: ['processRecommendations'] }),
  [GLOSSARY_TERM_VALUE.LABEL]: new GlossaryTerm({ description: ['treatmentInformation'] }),
  [GLOSSARY_TERM_VALUE.LOYALTY_PROGRAM]: new GlossaryTerm({ description: ['approachCalculate'] }),
  [GLOSSARY_TERM_VALUE.LOYALTY_BONUS]: new GlossaryTerm({ description: ['amountSkin'] }),
  [GLOSSARY_TERM_VALUE.MEDICAL_DATA]: new GlossaryTerm({ description: ['crucialQuestions'] }),
  [GLOSSARY_TERM_VALUE.MENU]: new GlossaryTerm({ description: ['setButton'] }),
  [GLOSSARY_TERM_VALUE.MESSAGE_TEMPLATES]: new GlossaryTerm({ description: ['visualLayouts'] }),
  [GLOSSARY_TERM_VALUE.NOTIFICATIONS]: new GlossaryTerm({ description: ['noticeSkin'] }),
  [GLOSSARY_TERM_VALUE.PATIENT_CARD]: new GlossaryTerm({ description: ['dedicatedPage'] }),
  [GLOSSARY_TERM_VALUE.PATIENT_LIST]: new GlossaryTerm({ description: ['allPatients'] }),
  [GLOSSARY_TERM_VALUE.PATIENT_CONSENT]: new GlossaryTerm({ description: ['consentLegalBases'] }),
  [GLOSSARY_TERM_VALUE.PDF_SUMMARY]: new GlossaryTerm({ description: ['diagnosticResults'] }),
  [GLOSSARY_TERM_VALUE.PHOTO_ANALYSIS]: new GlossaryTerm({ description: ['typeArtificial'] }),
  [GLOSSARY_TERM_VALUE.PRICE_SETTINGS]: new GlossaryTerm({ description: ['setOfPrices'] }),
  [GLOSSARY_TERM_VALUE.PRODUCT]: new GlossaryTerm({ description: ['cosmeticProduct'] }),
  [GLOSSARY_TERM_VALUE.PERSONAL_PHONE_NUMBER]: new GlossaryTerm({ description: ['phoneNumber'] }),
  [GLOSSARY_TERM_VALUE.PERSONALIZED_SERUM]: new GlossaryTerm({
    description: ['uniquelyPersonalized']
  }),
  [GLOSSARY_TERM_VALUE.QUICK_TREATMENT]: new GlossaryTerm({ description: ['wayCreate'] }),
  [GLOSSARY_TERM_VALUE.QUICK_LABEL]: new GlossaryTerm({ description: ['seeLabel'] }),
  [GLOSSARY_TERM_VALUE.QUESTIONNAIRE]: new GlossaryTerm({
    description: ['universkinQuestionnaire']
  }),
  [GLOSSARY_TERM_VALUE.REBALANCE]: new GlossaryTerm({ description: ['complementaryCare'] }),
  [GLOSSARY_TERM_VALUE.SERUM]: new GlossaryTerm({ description: ['advancedSkin'] }),
  [GLOSSARY_TERM_VALUE.SECONDARY_EMAIL]: new GlossaryTerm({ description: ['patientNotification'] }),
  [GLOSSARY_TERM_VALUE.STATUS]: new GlossaryTerm({
    description: [
      'pieceOfInfo',
      'newQuestionnaire',
      'ongoingTreatment',
      'treatmentStarts',
      'treatmentCompleted',
      'inactive'
    ]
  }),
  [GLOSSARY_TERM_VALUE.START_QUESTIONNAIRE]: new GlossaryTerm({ description: ['functionLaunch'] }),
  [GLOSSARY_TERM_VALUE.STATISTICS]: new GlossaryTerm({ description: ['keyIndicators'] }),
  [GLOSSARY_TERM_VALUE.SUMMARY]: new GlossaryTerm({ description: ['overviewCreated'] }),
  [GLOSSARY_TERM_VALUE.TREATMENT]: new GlossaryTerm({ description: ['setFace'] }),
  [GLOSSARY_TERM_VALUE.TREAT_FACE]: new GlossaryTerm({ description: ['includesUniverskin'] }),
  [GLOSSARY_TERM_VALUE.TUTORIAL]: new GlossaryTerm({ description: ['methodOfGet'] }),
  [GLOSSARY_TERM_VALUE.UNIQE_URL]: new GlossaryTerm({ description: ['ownLink', 'noteLink'] }),
  [GLOSSARY_TERM_VALUE.VALIDATION]: new GlossaryTerm({ description: ['indicatesPotential'] }),
  [GLOSSARY_TERM_VALUE.VALIDATE_TREATMENT]: new GlossaryTerm({ description: ['byValidating'] })
};

export default {
  name: 'GlossaryTermDescription',
  props: {
    termValue: {
      type: String,
      required: true
    }
  },
  computed: {
    isTextWithLinksDescription() {
      return TERMS_CONFIG[this.termValue].type === GLOSSARY_DESCRIPTION_TYPE.TEXT_WITH_LINKS;
    },
    termDescription() {
      return TERMS_CONFIG[this.termValue].description;
    },
    termAdditionalData() {
      return TERMS_CONFIG[this.termValue].additionalData;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';
</style>
