<template>
  <article class="loyalty-program-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.loyaltyProgram.mostDevotedPartners') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="participantListDesktopImage"
              alt="Participant list"
              class="loyalty-program-guide__participant-list-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="participantListMobileImage"
              alt="Participant list"
              class="loyalty-program-guide__participant-list-mobile-image user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="howToAccessLoyalty" class="subsection__subtitle">
          {{ $t('userGuide.title.howToAccessLoyalty') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              {{ $t('userGuide.loyaltyProgram.clickBurgerMenu') }}
            </li>
            <li>
              <i18n path="userGuide.loyaltyProgram.selectClickLoyalty" tag="span">
                <b class="mr-3">{{ $t('userGuide.loyaltyProgram.loyaltyProgram') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>
      </div>
    </section>

    <section class="article__subsection">
      <div id="participants" class="subsection__title">
        {{ $t('userGuide.title.participants') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.loyaltyProgram.systemAddsOne') }}
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="addParticipant" class="subsection__subtitle">
          {{ $t('userGuide.title.addParticipant') }}
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.loyaltyProgram.addNewParticipant" tag="span">
            <b>{{ $t('userGuide.loyaltyProgram.addParticipant') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.loyaltyProgram.note.nameShouldUnique') }}
          </span>
        </additional-info-section>

        <div id="participantStatus" class="subsection__subtitle">
          {{ $t('userGuide.title.participantStatus') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.loyaltyProgram.eachParticipant') }}
          <br />
          <span class="circle-icon circle-icon--grey"></span>
          {{ $t('userGuide.loyaltyProgram.greyStatus') }}
          <br />
          <span class="circle-icon circle-icon--bronze"></span>
          {{ $t('userGuide.loyaltyProgram.bronzeStatus') }}
          <br />
          <span class="circle-icon circle-icon--gold"></span>
          {{ $t('userGuide.loyaltyProgram.goldStatus') }}
        </div>

        <div class="subsection__text">
          {{ $t('userGuide.loyaltyProgram.giftUnavailable') }}
        </div>

        <div id="deleteParticipant" class="subsection__subtitle">
          {{ $t('userGuide.title.deleteParticipant') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.loyaltyProgram.deleteParticipant') }}
          <br />
          <b>1.</b>
          <i18n path="userGuide.loyaltyProgram.clickThreeDots" tag="span">
            <span class="subsection__svg-icon custom-icon icon-vertical-dots cursor-default"></span>
          </i18n>
          <br />
          <b>2.</b>
          {{ $t('userGuide.loyaltyProgram.selectParticipant') }}
          <br />
          <b>3.</b>
          <i18n path="userGuide.loyaltyProgram.clickDelete" tag="span">
            <b>{{ $t('userGuide.loyaltyProgram.delete') }}</b>
          </i18n>
          <br />
          <b>4.</b>
          {{ $t('userGuide.loyaltyProgram.systemAutomatically') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="skinPoints" class="subsection__title">{{ $t('userGuide.title.skinPoints') }}</div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.loyaltyProgram.duringTreatment') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="participantsSelectDesktopImage"
              alt="Participant select"
              class="loyalty-program-guide__participant-select-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="participantsSelectMobileImage"
              alt="Participant select"
              class="loyalty-program-guide__participant-select-mobile-image user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.loyaltyProgram.note.bonusAdded') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.loyaltyProgram.remember.productSelected') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="redeemOptions" class="subsection__title">
        {{ $t('userGuide.title.redeemOptions') }}
      </div>
      <div class="subsection__content subsection__text">
        {{ $t('userGuide.loyaltyProgram.participantCanAnyTime') }}
        <br />
        <br />
        {{ $t('userGuide.loyaltyProgram.doctorInCharge') }}
        <br />
        <br />
        {{ $t('userGuide.loyaltyProgram.participantWillThen') }}
      </div>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'LoyaltyProgramGuide',
  components: { OrderedList, AdditionalInfoSection, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    participantsSelectDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/loyalty-program/participant-select-desktop-${this.locale}.jpg`);
    },
    participantsSelectMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/loyalty-program/participant-select-mobile-${this.locale}.jpg`);
    },
    participantListDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/loyalty-program/participant-list-desktop-${this.locale}.jpg`);
    },
    participantListMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/loyalty-program/participant-list-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.circle-icon {
  width: 10px;
  height: 10px;

  border-radius: 50%;
  display: inline-block;

  margin-right: 2px;

  &--grey {
    background-color: $grey-color;
  }

  &--bronze {
    background-color: $bright-orange-color;
  }

  &--gold {
    background-color: $festival-color;
  }
}

.loyalty-program-guide {
  &__participant-list-desktop-image {
    min-height: 310px;
  }

  &__participant-select-desktop-image {
    min-height: 317px;
  }
}

@media (max-width: 1023px) {
  .loyalty-program-guide {
    &__participant-list-desktop-image {
      min-height: 294px;
    }
  }
}

@media (max-width: 767px) {
  .loyalty-program-guide {
    &__participant-list-desktop-image,
    &__participant-select-desktop-image {
      min-height: auto;
    }

    &__participant-list-mobile-image {
      min-height: 333px;
    }

    &__participant-select-mobile-image {
      min-height: 293px;
    }
  }
}
</style>
