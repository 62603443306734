<template>
  <article class="article">
    <section class="article__subsection">
      <div class="subsection__content subsection__text">
        {{ $t('userGuide.personalAccount.yourPersonalAccount') }}
      </div>
      <ordered-list>
        <template slot="list-items">
          <li>
            <span class="mr5">
              {{ $t('userGuide.personalAccount.clickBurgerMenu') }}
            </span>
            <span class="my-account__menu-icon fas fa-bars"></span>
          </li>
          <li>
            <span class="mr5">{{ $t('userGuide.personalAccount.click') }}</span>

            <router-link class="subsection__link mr5" :to="{ name: 'UserProfile' }" target="_blank">
              {{ $t('userGuide.personalAccount.myAccount') }}
            </router-link>

            <div
              class="my-account__link-icon icon-external-link custom-icon cursor-default inline-block"
            ></div>
          </li>
        </template>
      </ordered-list>
    </section>
    <user-guide-tabs class="mb30">
      <div slot="desktop-content">
        <img
          :src="personalAccountDesktopImages"
          alt="My Account"
          class="my-account__my-account-desktop-image user-guide-tabs__image"
        />
      </div>
      <div slot="mobile-content">
        <img
          :src="personalAccountMobileImages"
          alt="My Account"
          class="user-guide-tabs__image-mob"
        />
      </div>
    </user-guide-tabs>
    <section class="article__subsection">
      <div id="accountDetails" class="subsection__title">
        {{ $t('userGuide.title.accountDetails') }}
      </div>
      <div class="subsection__content subsection__content--with-indent">
        <div id="name" class="subsection__subtitle">
          {{ $t('userGuide.title.name') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.youCanChangeYourName') }}
        </div>
        <div id="photo" class="subsection__subtitle">
          {{ $t('userGuide.title.photo') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.uploadYourPhoto') }}
        </div>
        <div id="yourUniqueUrl" class="subsection__subtitle">
          {{ $t('userGuide.title.yourUniqueUrl') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.yourUniqueUrl') }}
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.personalAccount.tip.changeYourURL') }}
          </span>
        </additional-info-section>
        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.personalAccount.remember.copyOnYourClipboard') }}
          </span>
        </additional-info-section>
        <div id="yourWebsite" class="subsection__subtitle">
          {{ $t('userGuide.title.yourWebsite') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.addYourProfessional') }}
        </div>
        <div id="country" class="subsection__subtitle">
          {{ $t('userGuide.title.country') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.countryWherePractice') }}
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.personalAccount.note.availableUniverskinProducts') }}
          </span>
        </additional-info-section>
        <div id="emailAddress" class="subsection__subtitle">
          {{ $t('userGuide.title.emailAddress') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.emailAddressIsUsed') }}
          <br />
          {{ $t('userGuide.personalAccount.weSendNotifications') }}
          <br />
          - {{ $t('userGuide.personalAccount.patientCompletesQuestionnaire') }}
          <br />
          - {{ $t('userGuide.personalAccount.placesOnlineOrder') }}
          <br />
          - {{ $t('userGuide.personalAccount.patientTreatmentFinishes') }}
          <br />
          - {{ $t('userGuide.personalAccount.patientIsWaiting') }}
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.personalAccount.tip.youWouldLike') }}
          </span>
        </additional-info-section>
        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.personalAccount.note.linkToResetPassword') }}
          </span>
        </additional-info-section>
        <div id="secondaryEmailAddress" class="subsection__subtitle">
          {{ $t('userGuide.title.secondaryEmailAddress') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.opportunityToAddMoreEmail') }}
        </div>
        <div id="officePhoneNumber" class="subsection__subtitle">
          {{ $t('userGuide.title.officePhoneNumber') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.beAbleContact') }}
        </div>
        <div id="personalPhoneNumber" class="subsection__subtitle">
          {{ $t('userGuide.title.personalPhoneNumber') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.phoneNumberForSecurity') }}
        </div>
        <div id="clinicLocations" class="subsection__subtitle">
          {{ $t('userGuide.title.clinicLocations') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.tagPatients') }}
        </div>
        <div id="language" class="subsection__subtitle">
          {{ $t('userGuide.title.language') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.defaultLanguage') }}
        </div>
        <div id="statistics" class="subsection__subtitle">
          {{ $t('userGuide.title.statistics') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.personalAccount.statisticsSection') }}
        </div>
      </div>
    </section>
    <section class="article__subsection">
      <div id="howToResetPassword" class="subsection__title">
        {{ $t('userGuide.title.howToResetPassword') }}
      </div>

      <div class="subsection__content subsection__text">
        {{ $t('userGuide.personalAccount.canReset') }}
      </div>

      <user-guide-tabs class="mb30">
        <div slot="desktop-content">
          <img
            :src="resetPasswordDesktopImages"
            alt="My Account"
            class="my-account__reset-password-desktop-image user-guide-tabs__image"
          />
        </div>
        <div slot="mobile-content">
          <img
            :src="resetPasswordMobileImages"
            alt="My Account"
            class="my-account__reset-password-mobile-image user-guide-tabs__image"
          />
        </div>
      </user-guide-tabs>

      <ordered-list>
        <template slot="list-items">
          <li>{{ $t('userGuide.personalAccount.goTo') }}</li>
          <li>{{ $t('userGuide.personalAccount.clickForgotPassword') }}</li>
          <li>{{ $t('userGuide.personalAccount.enterYourEmail') }}</li>
          <li>{{ $t('userGuide.personalAccount.checkYourInbox') }}</li>
          <li>{{ $t('userGuide.personalAccount.enterYourNewPassword') }}</li>
        </template>
      </ordered-list>
    </section>
  </article>
</template>

<script>
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'PersonalAccount',
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  components: {
    OrderedList,
    UserGuideTabs,
    AdditionalInfoSection
  },
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    personalAccountDesktopImages() {
      return require(`@/assets/images/dashboard/user-guide/personal-account/my-account-desktop-${this.locale}.jpg`);
    },
    personalAccountMobileImages() {
      return require(`@/assets/images/dashboard/user-guide/personal-account/my-account-mobile-${this.locale}.jpg`);
    },
    resetPasswordDesktopImages() {
      return require(`@/assets/images/dashboard/user-guide/personal-account/reset-password-desktop-${this.locale}.jpg`);
    },
    resetPasswordMobileImages() {
      return require(`@/assets/images/dashboard/user-guide/personal-account/reset-password-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.my-account {
  &__menu-icon {
    vertical-align: middle;
  }

  &__link-icon {
    margin-bottom: -2px;
  }

  &__my-account-desktop-image {
    min-height: 393px;
  }

  &__reset-password-desktop-image {
    min-height: 316px;
  }

  &__reset-password-mobile-image {
    max-height: 417px;
  }
}

@media (max-width: 1023px) {
  .my-account {
    &__my-account-desktop-image {
      min-height: 245px;
    }

    &__reset-password-desktop-image {
      min-height: 196px;
    }
  }
}

@media (max-width: 767px) {
  .my-account {
    &__my-account-desktop-image {
      min-height: auto;
    }

    &__reset-password-desktop-image {
      min-height: auto;
    }

    &__reset-password-mobile-image {
      min-height: 309px;
    }
  }
}
</style>
