<template>
  <article class="questionnaire-guide article">
    <section class="article__subsection">
      <div id="whatIsQuestionnaire" class="subsection__title">
        {{ $t('userGuide.title.whatIsQuestionnaire') }}
      </div>
      <div class="subsection__content">
        <img
          class="questionnaire-guide__questionnaire-image subsection__centered-block mb10"
          :src="questionnaireImage"
          alt="Questionnaire"
        />

        <div class="subsection__inner-text">
          {{ $t('userGuide.questionnaire.theUniverskinQuestionnaire') }}
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.questionnaire.itIsComposed') }}
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.questionnaire.theAlghorithm') }}
          <br />
          <i18n path="userGuide.questionnaire.asSoonAsThePatient" tag="span">
            <router-link
              class="subsection__link mr-2"
              :to="
                $options.questionnairePageLinks.SKIN_DIAGNOSTIC_PAGE_DIAGNOSTIC_HISTORY_SUBSECTION
              "
            >
              {{ $t('userGuide.questionnaire.diagnosticsTab') }}
            </router-link>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.questionnaire.remember.youWillReceiveAnEmail') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="sendQuestionnaire" class="subsection__title">
        {{ $t('userGuide.title.sendQuestionnaire') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.questionnaire.youReceiveTheUnique') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.questionnaire.note.everyPatient') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          {{ $t('userGuide.questionnaire.ifYourPatients') }}
        </div>

        <div class="subsection__text">
          {{ $t('userGuide.questionnaire.chooseTheMostConvenient') }}
          <br />
          —
          <i18n path="userGuide.questionnaire.frormDashboard" tag="span">
            <router-link
              class="subsection__link"
              :to="$options.questionnairePageLinks.INVITE_PATIENTS"
            >
              {{ $t('userGuide.questionnaire.label.invitePatients') }}
            </router-link>
          </i18n>
          <br />
          —
          <i18n path="userGuide.questionnaire.fromPatientCard" tag="span">
            <router-link
              class="subsection__link"
              :to="$options.questionnairePageLinks.PATIENT_PAGE_PATIENT_CARD_SUBSECTION"
            >
              {{ $t('userGuide.questionnaire.label.patientCard') }}
            </router-link>
          </i18n>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="startQuestionnaire" class="subsection__title">
        {{ $t('userGuide.title.startQuestionnaire') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.questionnaire.youCanLaunch') }}
        </div>

        <div class="subsection__text">
          <i18n path="userGuide.questionnaire.youCanStart" tag="span">
            <b>{{ $t('userGuide.questionnaire.label.invitePatients') }}</b>
          </i18n>
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              <i18n path="userGuide.questionnaire.clickInvitePatientsButton" tag="span">
                <b>{{ $t('userGuide.questionnaire.label.invitePatients') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.questionnaire.clickQuestionnaireLink" tag="span">
                <b>{{ $t('userGuide.questionnaire.treatmentWithQuestionnaire') }}</b>
              </i18n>
            </li>

            <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
              <span slot="info-text">
                {{ $t('userGuide.questionnaire.note.afterClickingLink') }}
              </span>
            </additional-info-section>

            <li>
              <i18n path="userGuide.questionnaire.changeTheLanguage" tag="span">
                <b>{{ $t('userGuide.questionnaire.label.startButton') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.questionnaire.enterEmail" tag="span">
                <b>{{ $t('userGuide.questionnaire.label.next') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>
      </div>
    </section>
  </article>
</template>

<script>
import ArticleSectionRoute from '@/modules/dashboard/components/user-guide/articles/ArticleSectionRoute';

import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';

import { USER_GUIDE_ARTICLE_NAME } from '@/modules/dashboard/api/user-guide';
import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

const QUESTIONNAIRE_PAGE_LINKS = {
  SKIN_DIAGNOSTIC_PAGE_DIAGNOSTIC_HISTORY_SUBSECTION: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC,
    scrollId: 'diagnostic-history'
  }),
  PATIENT_PAGE_PATIENT_CARD_SUBSECTION: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.PATIENT,
    scrollId: 'patient-card'
  }),
  INVITE_PATIENTS: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS
  })
};

export default {
  name: 'QuestionnaireGuide',
  components: { OrderedList, AdditionalInfoSection },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  questionnairePageLinks: QUESTIONNAIRE_PAGE_LINKS,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    questionnaireImage() {
      return require(`@/assets/images/dashboard/user-guide/questionnaire/questionnaire-image-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.questionnaire-guide {
  &__questionnaire-image {
    width: 500px;
    min-height: 344px;
  }
}

@media (max-width: 1023px) {
  .questionnaire-guide {
    &__questionnaire-image {
      min-height: 299px;
    }
  }
}

@media (max-width: 767px) {
  .questionnaire-guide {
    &__questionnaire-image {
      min-height: auto;
    }
  }
}
</style>
