<template>
  <aside ref="sidebar" class="sidebar">
    <div class="sidebar__back-button pointer" @click="goToUserGuideMainPage">
      <div class="back-button__icon custom-icon icon-arrow-long-left mr7"></div>
      <span class="back-button__text">{{ $t('userGuide.label.userGuideHome') }}</span>
    </div>

    <div class="sidebar__subsections">
      <template v-for="subsection in subsections">
        <div
          :key="subsection"
          class="subsection__title pointer"
          :class="{ 'subsection__title--selected': subsection === selectedSubsection }"
          @click="scrollToSubsection(subsection)"
        >
          {{ getSubsectionLabel(subsection) }}
        </div>
      </template>
    </div>
  </aside>
</template>

<script>
import userGuideSidebarMixin from '@/modules/dashboard/components/mixins/userGuideSidebarMixin';

import { SCROLL_SUBSECTION_OFFSET } from '@/modules/dashboard/api/user-guide';

export default {
  name: 'ArticleSidebar',
  mixins: [userGuideSidebarMixin],
  props: {
    subsections: {
      type: Array,
      required: true
    },
    selectedSubsection: {
      type: String,
      required: true
    },
    isSubsectionKey: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getSubsectionLabel(subsection) {
      return this.isSubsectionKey ? this.$t(`userGuide.title.${subsection}`) : subsection;
    },
    scrollToSubsection(subsection) {
      this.$scrollTo(`#${subsection}`, 500, {
        offset: SCROLL_SUBSECTION_OFFSET,
        onDone: () => {
          this.$emit('select-subsection', subsection);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/user-guide/user-guide-sidebar';

.subsection {
  &__title {
    padding: 8px 15px;

    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    color: $text-color;

    &--selected {
      color: $orange-color;
      background: $white;
      border-radius: 5px;
    }
  }
}

@media (max-width: 767px) {
  .sidebar {
    &__subsections {
      display: none;
    }
  }
}
</style>
