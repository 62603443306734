<template>
  <article class="treatment-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.treatment.treatmentCombination') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="createTreatment" class="subsection__title">
        {{ $t('userGuide.title.createTreatment') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        <i18n path="userGuide.treatment.createPersonalizedTreatment" tag="span">
          <router-link
            class="subsection__link"
            :to="$options.treatmentPageLinks.TREATMENTS_DIRECTORY"
          >
            {{ $t('userGuide.treatment.label.treatmentsDirectory') }}
          </router-link>
        </i18n>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="createTreatmentNew" class="subsection__subtitle">
          {{ $t('userGuide.title.createTreatmentNew') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="createTreatmentButtonDesktopImage"
              alt="Create treatment button on dashboard"
              class="treatment-guide__create-treatment-button-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="createTreatmentButtonMobileImage"
              alt="Create treatment button on dashboard"
              class="treatment-guide__create-treatment-button-mobile-image user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              <i18n path="userGuide.treatment.goToDashboard" tag="span">
                <b>{{ $t('userGuide.treatment.clickCreateTreatment') }}</b>
              </i18n>
            </li>
            <li>
              <span>
                {{ $t('userGuide.treatment.selectOption') }}
                <br />
                {{ $t('userGuide.treatment.treatmentFromPhotoAnalysis') }}
                <br />
                {{ $t('userGuide.treatment.treatmentFromDirectory') }}
                <br />
                {{ $t('userGuide.treatment.treatmentFromScratch') }}
              </span>
            </li>
          </template>
        </ordered-list>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="treatmentModalDesktopImage"
              alt="New treatment modal"
              class="treatment-guide__new-treatment-modal-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="treatmentModalMobileImage"
              alt="New treatment modal"
              class="treatment-guide__new-treatment-modal-mobile-image user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__inner-text ml5">
          <b>{{ $t('userGuide.treatment.treatmentFromPhotoAnalysis') }}</b>
          <br />
          <i18n path="userGuide.treatment.enterBasicDetails" tag="span">
            <router-link class="subsection__link" :to="$options.treatmentPageLinks.PHOTO_ANALYSIS">
              {{ $t('userGuide.treatment.learnMore') }}
            </router-link>
          </i18n>
        </div>

        <div class="subsection__inner-text ml5">
          <b>{{ $t('userGuide.treatment.treatmentFromDirectory') }} </b>
          <br />
          <i18n path="userGuide.treatment.treatmentUsingFormulas" tag="span">
            <router-link
              class="subsection__link"
              :to="$options.treatmentPageLinks.TREATMENTS_DIRECTORY"
            >
              {{ $t('userGuide.treatment.learnMore') }}
            </router-link>
          </i18n>
        </div>

        <div class="subsection__inner-text ml5">
          <b>{{ $t('userGuide.treatment.treatmentFromScratch') }} </b>
          <br />
          {{ $t('userGuide.treatment.treatmentWithoutRecommendations') }}
        </div>

        <div id="createTreatmentExisting" class="subsection__subtitle">
          {{ $t('userGuide.title.createTreatmentExisting') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.findPatient') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="treatmentBasedDesktopImage"
              alt="Treatment based options"
              class="treatment-guide__treatment-based-options-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="treatmentBasedMobileImage"
              alt="Treatment based options"
              class="treatment-guide__treatment-based-options-mobile-image user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.dotsOptions" tag="span">
            <router-link
              class="subsection__link ml-3"
              :to="$options.treatmentPageLinks.SKIN_DIAGNOSTIC_PAGE_PHOTO_ANALYSIS_BASED_SUBTITLE"
            >
              {{ $t('userGuide.treatment.photoAnalysisBased') }}
            </router-link>
            <router-link
              class="subsection__link mr-3"
              :to="$options.treatmentPageLinks.SKIN_DIAGNOSTIC_PAGE_DIAGNOSTIC_BASED_SUBTITLE"
            >
              {{ $t('userGuide.treatment.questionnaireBased') }}
            </router-link>
            <router-link
              class="subsection__link mr-3"
              :to="$options.treatmentPageLinks.SKIN_DIAGNOSTIC_PAGE_DIAGNOSTIC_CREATION_SUBSECTION"
            >
              {{ $t('userGuide.treatment.generateDiagnostic') }}
            </router-link>
            <router-link
              class="subsection__link"
              :to="$options.treatmentPageLinks.TREATMENTS_DIRECTORY"
            >
              {{ $t('userGuide.title.treatmentsDirectory') }}
            </router-link>
          </i18n>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="treatSection" class="subsection__title">
        {{ $t('userGuide.title.treatSection') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.treatment.treatSection') }}
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="treatFace" class="subsection__subtitle">{{ $t('userGuide.title.treatFace') }}</div>
        <div class="subsection__text">
          {{ $t('userGuide.treatment.treatFace') }}
        </div>

        <img class="subsection__centered-block mb50 mt50" :src="serumCardImage" alt="Serum card" />

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.changesMake') }}
          <br />
          {{ $t('userGuide.treatment.changeNumber') }}
          <br />
          {{ $t('userGuide.treatment.addChangeRemove') }}
          <br />
          {{ $t('userGuide.treatment.seeActivities') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.algorithmDivides') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.modifyConcentrations') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.systemShow') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.setUpDay') }}
          <br />
          <i18n path="userGuide.treatment.duplicateFormula" tag="span">
            <span class="subsection__svg-icon custom-icon icon-copy-serum"></span>
          </i18n>
          <br />
          <i18n path="userGuide.treatment.microneedlingProcedure" tag="span">
            <span class="subsection__svg-icon custom-icon icon-microneedling-circle mr3"></span>
          </i18n>
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.everyTime') }}
          <br />
          {{ $t('userGuide.treatment.timeSlot') }}
          <br />
          {{ $t('userGuide.treatment.estimatedSerum') }}
          <br />
          {{ $t('userGuide.treatment.serumPrice') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.counterCalculates') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            <i18n path="userGuide.treatment.tip.clickRecalculate" tag="span">
              <b>{{ $t('userGuide.treatment.tip.recalculate') }}</b>
            </i18n>
          </span>
        </additional-info-section>

        <div id="treatEye" class="subsection__subtitle">{{ $t('userGuide.title.treatEye') }}</div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.treatEye" tag="span">
            <span class="subsection__svg-icon custom-icon icon-plus-active"></span>
          </i18n>
          <br />
          {{ $t('userGuide.treatment.modificationsYouCan') }}
          <br />
          {{ $t('userGuide.treatment.addchangeRemoveActive') }}
          <br />
          <i18n path="userGuide.treatment.seeActivitiesRecommendations" tag="span">
            <span class="subsection__svg-icon custom-icon icon-info"></span>
          </i18n>
          <br />
          {{ $t('userGuide.treatment.setUpDayNight') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            <i18n path="userGuide.treatment.tip.clickReset" tag="span">
              <b>{{ $t('userGuide.treatment.tip.reset') }}</b>
            </i18n>
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.youCanAdd') }}
          </span>
        </additional-info-section>

        <div id="universkinH" class="subsection__subtitle">
          {{ $t('userGuide.title.universkinH') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.universkinHUniversal') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.universkinHDisabled') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="complementaryProducts" class="subsection__title">
        {{ $t('userGuide.title.complementaryProducts') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.complementaryProducts') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.recommendedComplementary') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.recommendedComplementary') }}
          </span>
        </additional-info-section>

        <img
          class="treatment-guide__complementary-care-select-image subsection__centered-block mb20"
          :src="complementaryCareSelectImage"
          alt="Complementary care select"
        />

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.modificationsYouCanMake') }}
          <br />
          <i18n path="userGuide.treatment.clickIconAdd" tag="span">
            <span class="subsection__svg-icon custom-icon icon-plus-active"></span>
          </i18n>
          <br />
          {{ $t('userGuide.treatment.changeDayNight') }}
          <br />
          {{ $t('userGuide.treatment.changeProductVolume') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.onlyOneMoisturizer') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.remember.universkinHCanBe') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="treatmentValidation" class="subsection__title">
        {{ $t('userGuide.title.treatmentValidation') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.treatmentSetUp') }}
          <br />

          <i18n path="userGuide.treatment.createTreatmentOrsendOffer" tag="span">
            <b>{{ $t('userGuide.treatment.label.createTreatment') }}</b>
            <b>{{ $t('userGuide.treatment.label.sendOffer') }}</b>
          </i18n>
          <br />
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.useCreateTreatment" tag="span">
            <b>{{ $t('userGuide.treatment.label.createTreatment') }}</b>
          </i18n>
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              src="@/assets/images/dashboard/user-guide/treatment/summary-treatment-modal-desktop.jpg"
              alt="Summary treatment modal"
              class="treatment-guide__summary-treatment-modal-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              src="@/assets/images/dashboard/user-guide/treatment/summary-treatment-modal-mobile.jpg"
              alt="Summary treatment modal"
              class="treatment-guide__summary-treatment-modal-mobile-image user-guide-tabs__image"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.clickingCreateTreatmentButton" tag="span">
            <b>{{ $t('userGuide.treatment.clickCreateTreatment') }}</b>
          </i18n>
          <br />
          {{ $t('userGuide.treatment.reviewTreatmentComposition') }}
          <br />
          {{ $t('userGuide.treatment.selectParticipantSelectedTreatment') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.optionNotAvailable') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.defineTheTreatmentStart') }}
          <br />
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.tip.selectPastDate') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          {{ $t('userGuide.treatment.recapMessages') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.tip.changeNotificationsLanguage') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.printLabel" tag="span">
            <b>{{ $t('userGuide.treatment.label.printLabel') }}</b>
          </i18n>
          <br />
          <i18n path="userGuide.treatment.inciStickers" tag="span">
            <b>{{ $t('userGuide.treatment.quickLabelPrint') }}</b>
          </i18n>
        </div>

        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.useSendOfferOption" tag="span">
            <b>{{ $t('userGuide.treatment.label.sendOffer') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatment.note.optionNotAvailable') }}
          </span>
        </additional-info-section>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="sendOfferDesktopImage"
              alt="Send offer modal"
              class="treatment-guide__send-offer-modal-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="sendOfferMobileImage"
              alt="Send offer modal"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.clickingSendOfferButton" tag="span">
            <b>{{ $t('userGuide.treatment.clickSendOffer') }}</b>
          </i18n>
          <br />
          {{ $t('userGuide.treatment.reviewTreatmentComposition') }}
          <br />
          {{ $t('userGuide.treatment.selectParticipantComposedTreatment') }}
          <br />
          {{ $t('userGuide.treatment.recapMessages') }}
          <br />
        </div>

        <div class="subsection__inner-text">
          <i18n path="userGuide.treatment.forMoreInfoCheck" tag="span">
            <b>{{ $t('userGuide.treatment.onlineConciergeService') }}</b>
          </i18n>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="treatmentsHistory" class="subsection__title">
        {{ $t('userGuide.title.treatmentsHistory') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        <i18n path="userGuide.treatment.allTreatmentsRecorded" tag="span">
          <router-link
            class="subsection__link"
            :to="$options.treatmentPageLinks.PATIENT_PAGE_TREATMENT_HISTORY_SUBSECTION"
          >
            {{ $t('userGuide.treatment.learnMore') }}
          </router-link>
        </i18n>
      </div>
    </section>

    <section class="article__subsection">
      <div id="renewTreatment" class="subsection__title">
        {{ $t('userGuide.title.renewTreatment') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        <i18n path="userGuide.treatment.withinTreatmentsHistory" tag="span">
          <b>{{ $t('userGuide.treatment.renew') }}</b>
          <router-link class="subsection__link" :to="$options.treatmentPageLinks.MESSAGE_TEMPLATES">
            {{ $t('userGuide.treatment.learnMore') }}
          </router-link>
        </i18n>
      </div>
    </section>
  </article>
</template>

<script>
import ArticleSectionRoute from '@/modules/dashboard/components/user-guide/articles/ArticleSectionRoute';

import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';
import { USER_GUIDE_ARTICLE_NAME } from '@/modules/dashboard/api/user-guide';

const TREATMENT_PAGE_LINKS = {
  SKIN_DIAGNOSTIC_PAGE_DIAGNOSTIC_BASED_SUBTITLE: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC,
    scrollId: 'questionnaire-based-diagnostic'
  }),
  SKIN_DIAGNOSTIC_PAGE_PHOTO_ANALYSIS_BASED_SUBTITLE: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC,
    scrollId: 'diagnostic-based-on-photo-analysis'
  }),
  SKIN_DIAGNOSTIC_PAGE_DIAGNOSTIC_CREATION_SUBSECTION: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC,
    scrollId: 'diagnostic-creation'
  }),
  QUICK_LABEL_PRINT: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT
  }),
  PATIENT_PAGE_TREATMENT_HISTORY_SUBSECTION: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.PATIENT,
    scrollId: 'treatments-history'
  }),
  TREATMENTS_DIRECTORY: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY
  }),
  PHOTO_ANALYSIS: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS
  }),
  MESSAGE_TEMPLATES: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES,
    scrollId: 'notifications'
  })
};

export default {
  name: 'TreatmentGuide',
  components: { AdditionalInfoSection, OrderedList, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  treatmentPageLinks: TREATMENT_PAGE_LINKS,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    createTreatmentButtonDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/create-treatment-button-desktop-${this.locale}.jpg`);
    },
    createTreatmentButtonMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/create-treatment-button-mobile-${this.locale}.jpg`);
    },
    treatmentModalDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/new-treatment-modal-desktop-${this.locale}.jpg`);
    },
    treatmentModalMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/new-treatment-modal-mobile-${this.locale}.jpg`);
    },
    treatmentBasedDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/treatment-based-options-desktop-${this.locale}.jpg`);
    },
    treatmentBasedMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/treatment-based-options-mobile-${this.locale}.jpg`);
    },
    serumCardImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/serum-card-${this.locale}.jpg`);
    },
    complementaryCareSelectImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/complementary-care-select-${this.locale}.jpg`);
    },
    sendOfferDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/send-offer-desktop-${this.locale}.jpg`);
    },
    sendOfferMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/treatment/send-offer-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';

.icon-info {
  margin-left: 0;
}

.treatment-guide {
  &__create-treatment-button-desktop-image {
    min-height: 368px;
  }

  &__new-treatment-modal-desktop-image,
  &__summary-treatment-modal-desktop-image {
    min-height: 315px;
  }

  &__treatment-based-options-desktop-image {
    min-height: 217px;
  }

  &__complementary-care-select-image {
    min-height: 159px;
  }

  &__send-offer-modal-desktop-image {
    width: 500px;
  }
}

@media (max-width: 1023px) {
  .treatment-guide {
    &__create-treatment-button-desktop-image {
      min-height: 270px;
    }

    &__treatment-based-options-desktop-image {
      min-height: 159px;
    }
  }
}

@media (max-width: 767px) {
  .treatment-guide {
    &__create-treatment-button-desktop-image,
    &__new-treatment-modal-desktop-image,
    &__treatment-based-options-desktop-image,
    &__summary-treatment-modal-desktop-image {
      min-height: auto;
    }

    &__create-treatment-button-mobile-image {
      min-height: 293px;
    }

    &__new-treatment-modal-mobile-image,
    &__summary-treatment-modal-mobile-image {
      min-height: 338px;
    }

    &__treatment-based-options-mobile-image {
      min-height: 363px;
    }

    &__serum-card-image {
      min-height: 180px;
    }

    &__complementary-care-select-image {
      min-height: 101px;
    }
  }
}
</style>
