<template>
  <article class="photo-analysis-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.skinxsPhoto') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="analyzingPhotoDesktopImage"
              alt="Analyzing photo"
              class="photo-analysis-guide__analyzing-photo-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="analyzingPhotoMobileImage"
              alt="Analyzing photo"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>
      </div>
    </section>

    <section class="article__subsection">
      <div id="howToStartPhotoAnalysis" class="subsection__title">
        {{ $t('userGuide.title.howToStartPhotoAnalysis') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.runPhoto') }}
          <br />
          {{ $t('userGuide.photoAnalysis.yourDashboard') }}
          <br />
          {{ $t('userGuide.photoAnalysis.patientCard') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="photoAnalysisFromDashboard" class="subsection__subtitle">
          {{ $t('userGuide.title.photoAnalysisFromDashboard') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              <i18n path="userGuide.photoAnalysis.clickCreate" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.createTreatment') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.photoAnalysis.selectOption" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.quickTreatment') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.photoAnalysis.enterDetails" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.continue') }}</b>
              </i18n>
            </li>
            <li>
              {{ $t('userGuide.photoAnalysis.uploadFacePhoto') }}
            </li>
            <li>
              <i18n path="userGuide.photoAnalysis.confirmImage" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.continue') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>

        <div id="photoAnalysisFromPatientCard" class="subsection__subtitle">
          {{ $t('userGuide.title.photoAnalysisFromPatientCard') }}
        </div>

        <div class="subsection__text">
          <i18n path="userGuide.photoAnalysis.inYourPatientsCard" tag="span">
            <b>{{ $t('userGuide.photoAnalysis.label.startPhotoAnalysis') }}</b>
          </i18n>
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              <i18n path="userGuide.photoAnalysis.clickPhotoTab" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.photoTab') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.photoAnalysis.selectPhoto" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.addPhoto') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.photoAnalysis.clickQuick" tag="span">
                <b>{{ $t('userGuide.photoAnalysis.photoAnalysis') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.photoAnalysis.note.ifThereIsDiagnostic') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.photoAnalysis.note.ifPhotoUploaded') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="photoAnalysisProcess" class="subsection__title">
        {{ $t('userGuide.title.photoAnalysisProcess') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.photoAnalysis.onceYouStart') }}
          <br />
          <b>1.</b>
          {{ $t('userGuide.photoAnalysis.detectFaceOnPhoto') }}
          <br />
          <b>2.</b>
          {{ $t('userGuide.photoAnalysis.detectFaceCharacteristic') }}
          <br />
          <b>3.</b>
          {{ $t('userGuide.photoAnalysis.detectSpecificDysfunctions') }}
          <br />
          <b>4.</b>
          {{ $t('userGuide.photoAnalysis.provideAnalysis') }}
        </div>

        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.thenUpdate') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="analysisResultsUpdate" class="subsection__subtitle">
          {{ $t('userGuide.title.analysisResultsUpdate') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.allThisStep') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="analysisResultsDesktopImage"
              alt="Analysis results update"
              class="photo-analysis-guide__analysis-results-update-desktop user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="analysisResultsMobileImage"
              alt="Analysis results update"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <div id="clarificationQuestions" class="subsection__subtitle">
          {{ $t('userGuide.title.clarificationQuestions') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.systemMayAsk') }}
        </div>

        <div id="treatmentRecommendations" class="subsection__subtitle">
          {{ $t('userGuide.title.treatmentRecommendations') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.onceYouFinish') }}
        </div>

        <div id="diagnosticRecord" class="subsection__subtitle">
          {{ $t('userGuide.title.diagnosticRecord') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.photoAnalysis.toViewDiagnostic') }}
        </div>

        <div id="howToUploadPicture" class="subsection__subtitle">
          {{ $t('userGuide.title.howToUploadPicture') }}
        </div>

        <face-icons class="photo-analysis-face-icons flex--center--center mb20 mt20" />

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.photoAnalysis.tip.toObtainResults') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.photoAnalysis.note.theAlgorithm') }}
          </span>
        </additional-info-section>
      </div>
    </section>
  </article>
</template>

<script>
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import FaceIcons from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/FaceIcons';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'PhotoAnalysisGuide',
  components: { FaceIcons, OrderedList, UserGuideTabs, AdditionalInfoSection },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    analyzingPhotoDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/photo-analysis/analyzing-photo-desktop-${this.locale}.jpg`);
    },
    analyzingPhotoMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/photo-analysis/analyzing-photo-mobile-${this.locale}.jpg`);
    },
    analysisResultsDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/photo-analysis/analysis-results-update-desktop-${this.locale}.jpg`);
    },
    analysisResultsMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/photo-analysis/analysis-results-update-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';

.photo-analysis-guide {
  &__analyzing-photo-desktop-image,
  &__analysis-results-update-desktop {
    width: 500px;
    min-height: 439px;
  }
}

@media (max-width: 1023px) {
  .photo-analysis-guide {
    &__analyzing-photo-desktop-image,
    &__analysis-results-update-desktop {
      min-height: 345px;
    }
  }
}

@media (max-width: 767px) {
  .photo-analysis-guide {
    &__analyzing-photo-desktop-image,
    &__analysis-results-update-desktop {
      min-height: auto;
    }
  }
}

@media (max-width: 1023px) {
  .photo-analysis-face-icons {
    flex-direction: column;
  }

  /deep/ .face-icons__group:first-child {
    margin-bottom: 20px;
  }
}
</style>
