<template>
  <article class="price-settings-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.priceSettings.skinxsAllows') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="priceSettingsDesktopImage"
              alt="Price settings"
              class="user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="priceSettingsMobileImage"
              alt="Price settings"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>
      </div>

      <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.priceSettings.note.priceShown') }}
        </span>
      </additional-info-section>

      <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
        <span slot="info-text">
          {{ $t('userGuide.priceSettings.remember.changingPrice') }}
        </span>
      </additional-info-section>
    </section>

    <section class="article__subsection">
      <div id="howToAccess" class="subsection__title">
        {{ $t('userGuide.title.howToAccess') }}
      </div>

      <div class="subsection__content">
        <ordered-list class="mb20">
          <template slot="list-items">
            <li>{{ $t('userGuide.priceSettings.clickBurgerMenu') }}</li>
            <li>
              <i18n path="userGuide.priceSettings.click" tag="span">
                <b>{{ $t('userGuide.priceSettings.priceSettingsOption') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>
      </div>
    </section>

    <section class="article__subsection">
      <div id="activeIngridientPrice" class="subsection__title">
        {{ $t('userGuide.title.activeIngridientPrice') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.priceSettings.whenAdding') }}
        </div>
      </div>

      <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.priceSettings.note.activeIngridient') }}
        </span>
      </additional-info-section>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'PriceSettingsGuide',
  components: { OrderedList, AdditionalInfoSection, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    priceSettingsDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/price-settings/price-settings-desktop-${this.locale}.jpg`);
    },
    priceSettingsMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/price-settings/price-settings-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';
</style>
