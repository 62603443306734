<template>
  <div class="slide-content">
    <img :src="slideImageUrl" :alt="imageText" :class="imageStyles" />
    <div class="carousel flex justify-content--center">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="carousel__point"
        :class="{
          'carousel__point--current': getIsCurrentSlide(index),
          'carousel__point--previous': !getIsCurrentSlide(index)
        }"
        @click="selectSlide(index)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Slider',
  model: {
    prop: 'activeSlide',
    event: 'change'
  },
  props: {
    activeSlide: {
      type: Number,
      required: true
    },
    images: {
      type: Array,
      default: () => []
    },
    imageText: {
      type: String,
      default: ''
    },
    isMobileDevice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageStyles() {
      return {
        'user-guide-tabs__image': !this.isMobileDevice,
        'user-guide-tabs__image-mob': this.isMobileDevice
      };
    },
    slideImageUrl() {
      return this.images[this.activeSlide];
    }
  },
  methods: {
    selectSlide(slideIndex) {
      this.$emit('change', slideIndex);
    },
    getIsCurrentSlide(index) {
      return index === this.activeSlide;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/user-guide/article-shared';

.carousel {
  margin-top: 25px;
  text-align: center;

  &__point {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: $azure;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &--current {
      background-color: $orange-color;
    }

    &--previous {
      background-color: $dim-white;
    }
  }
}
</style>
