<template>
  <div class="status-list__container flex--center--center">
    <div class="status-list">
      <div
        v-for="status in statusList"
        :key="status.icon"
        class="flex--align-center status-list__status"
      >
        <slot name="status" v-bind="{ status }">
          <span class="status-icon flex--center--center">
            <span class="custom-icon" :class="status.icon"></span>
          </span>
          <span>{{ status.titleKeyPostfix }}</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusList',
  props: {
    statusList: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons';

.status-list {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: $grey-color;

  &__status:not(:last-child) {
    margin-bottom: 10px;
  }
}

.status-icon {
  width: 18px;
  margin-right: 4px;

  font-size: 16px;
}

.error-color {
  color: $geraldine;
}
</style>
