<template>
  <article class="treatments-directory-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.treatmentsDirectory.skinxsOffers') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.treatmentsDirectory.remember.canSelect') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text treatments-types">
          {{ $t('userGuide.treatmentsDirectory.fourTypesTreatments') }}
          <br />
          <span class="treatments-types__type">{{
            $t('userGuide.treatmentsDirectory.skinConditions')
          }}</span>
          <br />
          <span class="treatments-types__type">{{
            $t('userGuide.treatmentsDirectory.surgicalActs')
          }}</span>
          <br />
          <span class="treatments-types__type">{{
            $t('userGuide.treatmentsDirectory.nonSurgicalActs')
          }}</span>
          <br />
          <span class="treatments-types__type">{{
            $t('userGuide.treatmentsDirectory.microneedling')
          }}</span>
        </div>
      </div>
    </section>

    <section class="article__subsection mb40">
      <div id="usingTreatmentsDirectory" class="subsection__title">
        {{ $t('userGuide.title.usingTreatmentsDirectory') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.treatmentsDirectory.canAccess') }}
        <br />
        — {{ $t('userGuide.treatmentsDirectory.dashboard') }}
        <br />
        — {{ $t('userGuide.treatmentsDirectory.patientCard') }}
      </div>

      <div class="subsection__content subsection__content--with-indent mb40">
        <div id="usingFromDashboard" class="subsection__subtitle mb15">
          {{ $t('userGuide.title.usingFromDashboard') }}
        </div>

        <user-guide-tabs class="mb20 mt10">
          <div slot="desktop-content">
            <img
              :src="treatmentsDirectoryDesktopImage"
              alt="Using Treatments Directory from the dashboard"
              class="user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="treatmentsDirectoryMobileImage"
              alt="Using Treatments Directory from the dashboard"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              <i18n path="userGuide.treatmentsDirectory.clickButton" tag="span">
                <b>{{ $t('userGuide.treatmentsDirectory.createTreatmentOption') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.treatmentsDirectory.selectOption" tag="span">
                <b>{{ $t('userGuide.treatmentsDirectory.treatmentFromDirectory') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.treatmentsDirectory.enterBasicPatientsDetails" tag="span">
                <b>{{ $t('userGuide.treatmentsDirectory.continue') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.treatmentsDirectory.selectTypeAndAnswer" tag="span">
                <b>{{ $t('userGuide.treatmentsDirectory.seeRecommendedTreatments') }}</b>
              </i18n>
            </li>
            <li>
              {{ $t('userGuide.treatmentsDirectory.selectTreatment') }}
            </li>
          </template>
        </ordered-list>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="usingAtPatientsCard" class="subsection__subtitle">
          {{ $t('userGuide.title.usingAtPatientsCard') }}
        </div>
        <user-guide-tabs class="mb20 mt10">
          <div slot="desktop-content">
            <img
              :src="treatmentsDirectoryCardDesktopImage"
              alt="Using Treatments Directory at a patient card"
              class="treatment-guide__create-treatment-button-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="treatmentsDirectoryCardMobileImage"
              alt="Using Treatments Directory at a patient card"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              {{ $t('userGuide.treatmentsDirectory.goToCreateTreatment') }}
            </li>
            <li>
              <i18n path="userGuide.treatmentsDirectory.clickButton" tag="span">
                <b>{{ $t('userGuide.treatmentsDirectory.fromTreatmentsDirectory') }}</b>
              </i18n>
            </li>
            <li>
              <i18n path="userGuide.treatmentsDirectory.selectTypeOfTreatment" tag="span">
                <b>{{ $t('userGuide.treatmentsDirectory.seeRecommendedTreatments') }}</b>
              </i18n>
            </li>
            <li>{{ $t('userGuide.treatmentsDirectory.selectTreatmentFromList') }}</li>
          </template>
        </ordered-list>
      </div>
    </section>

    <section class="article__subsection">
      <div id="notices" class="subsection__title">
        {{ $t('userGuide.title.notices') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.treatmentsDirectory.asGenericTreatments') }}
      </div>
      <div class="image-container">
        <img
          class="subsection__centered-block mb20"
          :src="treatmentsDirectoryNoticesImage"
          alt="Notices"
        />
      </div>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentsDirectoryGuide',
  components: { AdditionalInfoSection, OrderedList, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    treatmentsDirectoryDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/treatments-directory/treatments-directory-dashboard-desktop-${this.locale}.jpg`);
    },
    treatmentsDirectoryMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/treatments-directory/treatments-directory-dashboard-mobile-${this.locale}.jpg`);
    },
    treatmentsDirectoryCardDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/treatments-directory/treatments-directory-card-desktop-${this.locale}.jpg`);
    },
    treatmentsDirectoryCardMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/treatments-directory/treatments-directory-card-mobile-${this.locale}.jpg`);
    },
    treatmentsDirectoryNoticesImage() {
      return require(`@/assets/images/dashboard/user-guide/treatments-directory/treatments-directory-notices-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';

.image-container {
  margin-top: -1px;
  padding: 20px 35px;
  min-height: 140px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.treatments-types__type::before {
  content: '\2022';
  display: inline-block;
  padding-right: 5px;
}
</style>
