<template>
  <article class="article">
    <img
      src="@/assets/images/dashboard/icons/skinxs-logo-large.svg"
      alt="About SkinXs"
      class="article__skinxs-logo"
    />
    <section class="article__subsection">
      <div id="whatIsRational" class="subsection__title">
        {{ $t('userGuide.title.whatIsRational') }}
      </div>
      <div class="subsection__content">
        {{ $t('userGuide.aboutSkinxs.thanksToUniverskin') }}
        <br />
        {{ $t('userGuide.aboutSkinxs.inWay') }}
        <br />
        {{ $t('userGuide.aboutSkinxs.thanksToSkinxs') }}
      </div>
    </section>
    <section class="article__subsection">
      <div id="whatIsSkinxs" class="subsection__title">
        {{ $t('userGuide.title.whatIsSkinxs') }}
      </div>
      <div class="subsection__content">
        {{ $t('userGuide.aboutSkinxs.skinxsDigitalPlatform') }}
      </div>
    </section>
    <section class="article__subsection">
      <div id="whatAreBenefits" class="subsection__title">
        {{ $t('userGuide.title.whatAreBenefits') }}
      </div>
      <div class="subsection__content">
        {{ $t('userGuide.aboutSkinxs.firstIsTimeSaving') }}
        <br />
        {{ $t('userGuide.aboutSkinxs.secondly') }}
        <br />
        {{ $t('userGuide.aboutSkinxs.skinxsAlsoWorks') }}
      </div>
    </section>
    <section class="article__subsection">
      <div id="dataPrivacy" class="subsection__title">
        {{ $t('userGuide.title.dataPrivacy') }}
      </div>
      <div class="subsection__content">
        {{ $t('userGuide.aboutSkinxs.patientsData') }}
      </div>
    </section>
  </article>
</template>

<script>
import ANIMATION_SRC from '@/assets/images/dashboard/user-guide/about-skinxs/about-skinxs-animation.gif';

export default {
  name: 'AboutSkinxs',
  animationSrc: ANIMATION_SRC
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/user-guide/article-shared';

.article {
  &__skinxs-logo {
    display: block;
    height: 196px;
    margin: 50px auto;
  }
}

.subsection {
  &__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .article {
    &__skinxs-logo {
      max-width: 100%;
      height: 140px;
      margin: 40px auto;
    }
  }
}
</style>
