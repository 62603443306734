<template>
  <article class="inviting-patients-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.inviteYourPatients') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="invitePatientsDesktopImage"
              alt="Send invites modal"
              class="inviting-patients-guide__send-invites-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="invitePatientsMobileImage"
              alt="Send invites page"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>
      </div>
    </section>

    <section class="article__subsection">
      <div id="campaigns" class="subsection__title">{{ $t('userGuide.title.campaigns') }}</div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.campaignsAreCommunicationTools') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.inviteNewPatients') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.howToUseCampaignTool') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>{{ $t('userGuide.invitingPatients.goToYourSkinxs') }}</li>
            <li>{{ $t('userGuide.invitingPatients.clickOnInvite') }}</li>
            <li>{{ $t('userGuide.invitingPatients.chooseOne') }}</li>
          </template>
        </ordered-list>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="facebook" class="subsection__subtitle">{{ $t('userGuide.title.facebook') }}</div>
        <div class="subsection__text">
          {{ $t('userGuide.invitingPatients.afterClickingTheFacebook') }}
        </div>

        <div id="email" class="subsection__subtitle">{{ $t('userGuide.title.email') }}</div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.whenChoosingTheEmail') }}
          <br />
          {{ $t('userGuide.invitingPatients.enterAsManyEmail') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.invitingPatients.note.systemWillNotAllow') }}
          </span>
        </additional-info-section>

        <div id="sms" class="subsection__subtitle">{{ $t('userGuide.title.sms') }}</div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.sendCampaignsUsingSms') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.invitingPatients.remember.smsCounterIsInstalled') }}
          </span>
        </additional-info-section>

        <div id="shareTheLink" class="subsection__subtitle">
          {{ $t('userGuide.title.shareTheLink') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.sharePersonalQuestionnaireLink') }}
          <br />
          {{ $t('userGuide.invitingPatients.steps') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>{{ $t('userGuide.invitingPatients.clickInvitePatients') }}</li>
            <li>{{ $t('userGuide.invitingPatients.clickCopyLink') }}</li>
            <li>{{ $t('userGuide.invitingPatients.pasteLinkAnywhere') }}</li>
          </template>
        </ordered-list>
      </div>
    </section>

    <section class="article__subsection">
      <div id="sendAnInvite" class="subsection__title">
        {{ $t('userGuide.title.sendAnInvite') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.invitingPatients.invitePatientCompleteQuestionnaire') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>{{ $t('userGuide.invitingPatients.findPatientYouWant') }}</li>
            <li>{{ $t('userGuide.invitingPatients.clickSendQuestionnaireButton') }}</li>
            <li>{{ $t('userGuide.invitingPatients.chooseWayYouWant') }}</li>
          </template>
        </ordered-list>
      </div>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'InvitingPatients',
  components: { AdditionalInfoSection, OrderedList, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    invitePatientsDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/inviting-patients/send-invites-desktop-${this.locale}.jpg`);
    },
    invitePatientsMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/inviting-patients/send-invites-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.inviting-patients-guide {
  &__send-invites-desktop-image {
    width: 500px;
    min-height: 440px;
  }
}

@media (max-width: 1023px) {
  .inviting-patients-guide {
    &__send-invites-desktop-image {
      min-height: 346px;
    }
  }
}

@media (max-width: 767px) {
  .inviting-patients-guide {
    &__send-invites-desktop-image {
      min-height: auto;
    }
  }
}
</style>
