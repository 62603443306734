<template>
  <article>
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.notifications.skinXsNotifications') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="notificationBellDesktopImage"
              alt="Desktop Notification Bell"
              class="user-guide-tabs__image notifications-guide__notification-bell-desktop"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="notificationBellMobileImage"
              alt="Mobile Notification Bell"
              class="user-guide-tabs__image-mob notifications-guide__notification-bell-mobile"
            />
          </div>
        </user-guide-tabs>

        {{ $t('userGuide.notifications.twoWays') }}
        <br />
        {{ $t('userGuide.notifications.notificationBell') }}
        <br />
        {{ $t('userGuide.notifications.notificationsSentEmail') }}
      </div>
    </section>

    <section class="article__subsection">
      <div id="notificationBell" class="subsection__title">
        <span
          class="custom-icon icon-notifications-bell inline-block vertical-align-middle cursor-default"
        ></span>
        {{ $t('userGuide.title.notificationBell') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.notifications.viewYourNotifications') }}
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.notifications.twoTypesNotifications') }}
          <br />
          <b>{{ $t('userGuide.notifications.newQuestionnaireFilled') }}</b>
          {{ $t('userGuide.notifications.systemNotifiesPatientCompleted') }}
          <br />
          <b>{{ $t('userGuide.notifications.orderUnderDelivery') }}</b>
          {{ $t('userGuide.notifications.systemNotifiesPatientPaid') }}
          <br />
          <b>{{ $t('userGuide.notifications.completedTreatment') }}</b>
          {{ $t('userGuide.notifications.systemNotifiesTreatmentCompleted') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.notifications.note.notificationsDispllayed') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.notifications.note.createValidateTreatment') }}
          </span>
        </additional-info-section>

        <div class="subsection__content subsection__content--with-indent">
          <div id="howItWorks" class="subsection__subtitle">
            {{ $t('userGuide.title.howItWorks') }}
          </div>
          <ordered-list>
            <template slot="list-items">
              <li>
                {{ $t('userGuide.notifications.clickNotificationBell') }}
              </li>
              <additional-info-section
                :info-type="$options.additionalInfoType.REMEMBER"
                class="mb20"
              >
                <span slot="info-text">
                  {{ $t('userGuide.notifications.remember.iconNotification') }}
                </span>
              </additional-info-section>
              <li>
                <span class="mt4">
                  <i18n path="userGuide.notifications.seeDetails" tag="span">
                    <span class="icon-vertical-dots inline-block vertical-align-middle"></span>
                  </i18n>
                </span>
              </li>
              <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
                <span slot="info-text">
                  <i18n path="userGuide.notifications.tip.clickMarkAll" tag="span">
                    <b>{{ $t('userGuide.notifications.tip.markAllAsRead') }}</b>
                  </i18n>
                </span>
              </additional-info-section>

              {{ $t('userGuide.notifications.clickingNotification') }}
              <br />
              {{ $t('userGuide.notifications.filledNewQuestionnaire') }}
              <br />
              {{ $t('userGuide.notifications.notificationCompletedTreatment') }}
            </template>
          </ordered-list>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="notificationsSentByEmail" class="subsection__title">
        {{ $t('userGuide.title.notificationsSentByEmail') }}
      </div>
      <div class="subsection__content">
        {{ $t('userGuide.notifications.typesEmail') }}
        <br />
        <b>1.</b>
        {{ $t('userGuide.notifications.informAboutNew') }}
        <br />
        <b>2.</b>
        {{ $t('userGuide.notifications.remindPatient') }}
        <br />
        <b>3.</b>
        {{ $t('userGuide.notifications.informTreatmentCompleted') }}
        <br />
        <b>4.</b>
        {{ $t('userGuide.notifications.informPatientPaidOrder') }}
      </div>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'NotificationsGuide',
  components: {
    UserGuideTabs,
    AdditionalInfoSection,
    OrderedList
  },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    notificationBellDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/notifications/notification-bell-desktop-${this.locale}.jpg`);
    },
    notificationBellMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/notifications/notification-bell-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.notifications-guide {
  &__notification-bell {
    &-desktop {
      width: 374px;
      min-height: 302px;
    }

    &-mobile {
      width: 250px;
    }
  }
}

@media (max-width: 767px) {
  .notifications-guide {
    &__notification-bell {
      &-desktop {
        width: auto;
        min-height: auto;
        max-height: 302px;
      }
    }
  }
}
</style>
