<template>
  <div class="template">
    <header class="template__header">
      <div class="header__icon custom-icon mr10 cursor-default" :class="articleIcon"></div>
      <span :id="titleId" class="header__title">{{ title }}</span>
    </header>
    <slot name="content"></slot>
  </div>
</template>

<script>
import { kebabCase } from 'lodash';

import { ARTICLE_COMPONENT_CONFIG } from '@/modules/dashboard/api/user-guide';

export default {
  name: 'ArticleTemplate',
  props: {
    articleName: {
      type: String,
      required: true
    }
  },
  computed: {
    title() {
      return this.$t(`userGuide.title.${ARTICLE_COMPONENT_CONFIG[this.articleName].title}`);
    },
    titleId() {
      return kebabCase(this.title);
    },
    articleIcon() {
      return ARTICLE_COMPONENT_CONFIG[this.articleName].icon;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons';

.template {
  width: calc(100% - 245px);
  margin-left: 245px;
  padding: 35px 30px;

  background: $white;
  border-radius: 10px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}

.header {
  &__title {
    line-height: 20px;
    font-size: 18px;
    font-weight: 600;
    color: $main-title-color;
  }
}

@media (max-width: 767px) {
  .template {
    width: 100%;
    margin-left: 0;
    padding: 35px 15px;
  }
}
</style>
