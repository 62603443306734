<template>
  <article class="patient-guide article">
    <section class="article__subsection">
      <div id="createPatient" class="subsection__title">
        {{ $t('userGuide.title.createPatient') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.firstStepStartWorking') }}
          <br />
          <i18n path="userGuide.patient.createRecord" tag="span">
            <b> {{ $t('userGuide.patient.createTreatment') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            <i18n path="userGuide.patient.note.doNotFinishPhotoAnalysis" tag="span">
              <b>{{ $t('userGuide.patient.label.treatmentWithPhotoAnalysis') }}</b>
            </i18n>
          </span>
        </additional-info-section>
      </div>
      <div class="subsection__content subsection__content--with-indent">
        <div id="createPatientCard" class="subsection__subtitle">
          {{ $t('userGuide.title.createPatientCard') }}
        </div>
        <div class="subsection__inner-text">
          - {{ $t('userGuide.patient.createPatientProvideData') }}
          <br />
          - {{ $t('userGuide.patient.createPatientAcceptConsent') }}
          <br />
          - {{ $t('userGuide.patient.createPatientClickButton') }}
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.note.uniqueIdentifier') }}
          </span>
        </additional-info-section>

        <div id="howToAvoid" class="subsection__subtitle">
          {{ $t('userGuide.title.howToAvoid') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.patient.systemVerifies') }}
          <br />
          {{ $t('userGuide.patient.emailRegistered') }}
          <br />
          {{ $t('userGuide.patient.emailRegisteredAnotherDoctor') }}
          <br />
          {{ $t('userGuide.patient.youCanNotCreate') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="contactPatient" class="subsection__title">
        {{ $t('userGuide.title.contactPatient') }}
      </div>
      <div class="subsection__content subsection__text">
        <i18n path="userGuide.patient.contactFromDashboard" tag="span">
          <span class="icon-vertical-dots inline-block vertical-align-middle"></span>
        </i18n>
      </div>
    </section>

    <section class="article__subsection">
      <div id="patientCard" class="subsection__title">
        {{ $t('userGuide.title.patientCard') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.accessPatientCard') }}
          <br />
          • {{ $t('userGuide.patient.clickRow') }}
          <br />
          • {{ $t('userGuide.patient.clickName') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.seeGeneralPatietnInfo') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="patientInfoDesktopImage"
              alt="Patient info"
              class="patient-guide__patient-info-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="patientInfoMobileImage"
              alt="Patient info"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="userpic" class="subsection__subtitle">
          {{ $t('userGuide.title.userpic') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.firstPhotoUploaded') }}
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.tip.changePatientProfilePhoto') }}
          </span>
        </additional-info-section>

        <div id="status" class="subsection__subtitle">{{ $t('userGuide.title.status') }}</div>
        <div class="subsection__text">
          {{ $t('userGuide.patient.reflectPatietnTreatment') }}
        </div>

        <div id="updatePatientProfile" class="subsection__subtitle">
          {{ $t('userGuide.title.updatePatientProfile') }}
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.patient.editPatientProfile" tag="span">
            <b> {{ $t('userGuide.patient.label.edit') }}</b>
          </i18n>
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.tip.phoneNumberCheckbox') }}
          </span>
        </additional-info-section>

        <div id="startPhotoAnalysis" class="subsection__subtitle">
          {{ $t('userGuide.title.startPhotoAnalysis') }}
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.patient.createPhotoAnalysis" tag="span">
            <router-link
              class="subsection__link"
              :to="$options.patientPageLinks.SKIN_DIAGNOSTIC_PAGE"
            >
              {{ $t('userGuide.patient.label.photoAnalysisBasedDiagnostic') }}
            </router-link>
          </i18n>
        </div>

        <div id="sendQuestionnaire" class="subsection__subtitle">
          {{ $t('userGuide.title.sendQuestionnaire') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.sendQuestionnaireLink') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.patient.tip.takeAdvantage') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.remember.smsLimit') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="createTreatmentTab" class="subsection__title">
        {{ $t('userGuide.title.createTreatmentTab') }}
      </div>
      <div class="subsection__content">
        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="patientsCardTabsDesktopImage"
              alt="Create treatment tabs"
              class="patient-guide__patient-card-tabs-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="patientsCardTabsMobileImage"
              alt="Create treatment tabs"
              class="patient-guide__patient-card-tabs-mobile-image user-guide-tabs__image"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.createTreatmentTabOpened') }}
        </div>
        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.note.createTreatmentAnomalies') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="treatmentsHistory" class="subsection__title">
        {{ $t('userGuide.title.treatmentsHistory') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.patient.accessTreatments') }}
        </div>
        <img
          class="patient-guide__treatment-history-section-image subsection__centered-block mb20"
          :src="treatmentsHistoryImage"
          alt="Treatment history section"
        />

        <div class="subsection__inner-text">
          <i18n path="userGuide.patient.treatmentHistoryRenew" tag="span">
            <b> {{ $t('userGuide.patient.label.renew') }}</b>
          </i18n>
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.patient.forTreatmentOffers" tag="span">
            <b> {{ $t('userGuide.patient.labelOfferLink') }}</b>
          </i18n>
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.printLabelButton') }}
        </div>
        <div class="subsection__text">
          <i18n path="userGuide.patient.clickTrashIcon" tag="span">
            <span class="trash-icon far fa-trash-alt subsection__text-icon"></span>
          </i18n>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="diagnosticsHistory" class="subsection__title">
        {{ $t('userGuide.title.diagnosticsHistory') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.patient.saveDiagnosticHistory') }}
          <br />
          {{ $t('userGuide.patient.findDetailedSectionDescription') }}
        </div>

        <img
          class="patient-guide__diagnostic-history-section-image subsection__centered-block mb20"
          :src="diagnosticHistoryImage"
          alt="Diagnostic history section"
        />

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.patient.note.warningAboutAnomalies') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          <i18n path="userGuide.patient.anomaliesDescription" tag="span">
            <span class="exclamation-icon fas fa-exclamation-triangle subsection__text-icon"></span>
          </i18n>
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.dependingOnPatientSeeAnomalies') }}
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.issuesDescription') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.patient.tip.markAnomaly') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.patient.note.unvalidatedAnomaly') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          <i18n path="userGuide.patient.deleteDiagnosticOfInterest" tag="span">
            <span class="trash-icon far fa-trash-alt subsection__text-icon"></span>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.note.deleteDiagnosticThereIsTreatment') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="medicalData" class="subsection__title">{{ $t('userGuide.title.medicalData') }}</div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.medicalDataQuestions') }}
          <br />
          {{ $t('userGuide.patient.viewData') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.remember.medicalDataQuestionsNotListed') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="photos" class="subsection__title">{{ $t('userGuide.title.photos') }}</div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.observePatientSkinDysfunctions') }}
          <br />
          {{ $t('userGuide.patient.photosUploaded') }}
          <br />
          {{ $t('userGuide.patient.maxPhotoUpload') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.patient.remember.runPhotoAnalysis') }}
          </span>
        </additional-info-section>
      </div>
      <div class="subsection__content subsection__content--with-indent">
        <div id="comparePhotos" class="subsection__subtitle">
          {{ $t('userGuide.title.comparePhotos') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.patient.seeTreatmentDynamic') }}
        </div>

        <ordered-list>
          <template slot="list-items">
            <li>{{ $t('userGuide.patient.compareButton') }}</li>
            <li>{{ $t('userGuide.patient.twoSectionsInside') }}</li>
            <li>{{ $t('userGuide.patient.seeLastUploadedPhoto') }}</li>
            <li>{{ $t('userGuide.patient.selectPhotoToCompare') }}</li>
          </template>
        </ordered-list>
      </div>
    </section>

    <section class="article__subsection">
      <div id="comments" class="subsection__title">{{ $t('userGuide.title.comments') }}</div>
      <div class="subsection__content subsection__text">
        {{ $t('userGuide.patient.writeDownImportantInformation') }}
        <br />
        {{ $t('userGuide.patient.commentWithDate') }}
        <br />
        {{ $t('userGuide.patient.commentIcon') }}
      </div>
    </section>
  </article>
</template>

<script>
import ArticleSectionRoute from '@/modules/dashboard/components/user-guide/articles/ArticleSectionRoute';

import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';
import { USER_GUIDE_ARTICLE_NAME } from '@/modules/dashboard/api/user-guide';

const PATIENT_PAGE_LINKS = {
  SKIN_DIAGNOSTIC_PAGE: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC,
    scrollId: 'diagnosticBasedOnPhotoAnalysis'
  })
};

export default {
  name: 'Patient',
  components: { OrderedList, UserGuideTabs, AdditionalInfoSection },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  patientPageLinks: PATIENT_PAGE_LINKS,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    patientInfoDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/patient/patient-info-desktop-${this.locale}.jpg`);
    },
    patientInfoMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/patient/patient-info-mobile-${this.locale}.jpg`);
    },
    patientsCardTabsDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/patient/patient-card-tabs-desktop-${this.locale}.jpg`);
    },
    patientsCardTabsMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/patient/patient-card-tabs-mobile-${this.locale}.jpg`);
    },
    treatmentsHistoryImage() {
      return require(`@/assets/images/dashboard/user-guide/patient/treatment-history-section-${this.locale}.jpg`);
    },
    diagnosticHistoryImage() {
      return require(`@/assets/images/dashboard/user-guide/patient/diagnostic-history-section-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.trash-icon {
  color: $grey-color;
}

.exclamation-icon {
  color: $geraldine;
}

.patient-guide {
  &__patient-info-desktop-image {
    min-height: 233px;
  }

  &__patient-card-tabs-desktop-image {
    min-height: 46px;
  }

  &__patient-card-tabs-mobile-image {
    width: 290px;
  }

  &__treatment-history-section-image,
  &__diagnostic-history-section-image {
    width: 330px;
    min-height: 105px;
  }
}

@media (max-width: 1023px) {
  .patient-guide {
    &__patient-info-desktop-image {
      min-height: 145px;
    }

    &__patient-card-tabs-desktop-image {
      min-height: 28px;
    }
  }
}

@media (max-width: 767px) {
  .patient-guide {
    &__patient-info-desktop-image {
      min-height: auto;
    }

    &__patient-card-tabs-desktop-image {
      min-height: auto;
    }

    &__patient-card-tabs-mobile-image {
      min-height: 327px;
    }

    &__treatment-history-section-image,
    &__diagnostic-history-section-image {
      min-height: 84px;
    }
  }
}
</style>
