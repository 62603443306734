<template>
  <ol class="list">
    <slot name="list-items"></slot>
  </ol>
</template>

<script>
export default {
  name: 'OrderedList'
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.list {
  display: flex;
  flex-direction: column;

  list-style: none;
  counter-reset: list-counter;
  padding-inline-start: 0;
  padding-left: 0;

  & li {
    position: relative;
    padding-left: 40px;
    min-height: 30px;

    display: flex;
    align-items: center;

    counter-increment: list-counter;
    font-size: 12px;
    color: $text-color;

    &::before {
      position: absolute;
      top: 0;
      left: 0;

      content: counter(list-counter);
      width: 30px;
      height: 30px;
      display: inline-block;
      background-color: $dim-white;
      border-radius: 50%;
      line-height: 30px;
      color: $orange-color;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
