<template>
  <article class="microneedling-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <img
          src="@/assets/images/dashboard/user-guide/microneedling/microneedling.jpg"
          alt="Universkin s.tep"
          class="user-guide-tabs__image mb20"
        />

        <div class="subsection__content">
          {{ $t('userGuide.microneedling.universkinDelivers') }}
        </div>
        <div class="subsection__inner-text">
          • {{ $t('userGuide.microneedling.easierActive') }}
          <br />
          • {{ $t('userGuide.microneedling.enhancedActive') }}
          <br />
          • {{ $t('userGuide.microneedling.maximizedTreatment') }}
          <br />
          • {{ $t('userGuide.microneedling.transientInjury') }}
          <br />
          • {{ $t('userGuide.microneedling.newCollagen') }}
          <br />
          • {{ $t('userGuide.microneedling.improvedSkin') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.microneedling.contactUniverskin') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="microneedlingTreatment" class="subsection__title">
        {{ $t('userGuide.title.microneedlingTreatment') }}
      </div>
      <div class="subsection__content subsection__content--with-indent">
        <div class="subsection__content subsection__inner-text">
          {{ $t('userGuide.microneedling.applyProcedure') }}
        </div>

        <div id="recommendedFormulas" class="subsection__subtitle">
          {{ $t('userGuide.title.recommendedFormulas') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.microneedling.tip.treatmentsDirectory') }}
          </span>
        </additional-info-section>
      </div>
      <user-guide-tabs class="mb20">
        <div slot="desktop-content">
          <img
            :src="microneedlingTreatmentDesktopImage"
            alt="S.tep treatment"
            class="user-guide-tabs__image"
          />
        </div>
        <div slot="mobile-content">
          <img
            :src="microneedlingTreatmentMobileImage"
            alt="S.tep treatment"
            class="user-guide-tabs__image-mob"
          />
        </div>
      </user-guide-tabs>

      <div id="settingUpProcedure" class="subsection__subtitle">
        {{ $t('userGuide.title.settingUpProcedure') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.creatingTreatment') }}
      </div>

      <ordered-list class="mb20">
        <template slot="list-items">
          <li>
            {{ $t('userGuide.microneedling.clickAddProcedure') }}
          </li>
          <li>
            {{ $t('userGuide.microneedling.forFaceSerum') }}
          </li>
        </template>
      </ordered-list>

      <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.microneedling.note.addProcedureToOneSerum') }}
        </span>
      </additional-info-section>

      <img
        :src="settingTreatmentImage"
        alt="Setting up s.tep treatment"
        class="user-guide-tabs__image mb20 mt10"
      />

      <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.microneedling.treatmentDuration') }}
        </span>
      </additional-info-section>

      <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.microneedling.includingStep') }}
        </span>
      </additional-info-section>

      <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.microneedling.remember.procedureTobePerformed') }}
        </span>
      </additional-info-section>

      <div id="postprocedural" class="subsection__subtitle">
        {{ $t('userGuide.title.postprocedural') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.afterProcedureSkinXs') }}
      </div>
    </section>

    <section class="article__subsection">
      <div id="contraindicationsandLmitations" class="subsection__title">
        {{ $t('userGuide.title.contraindicationsandLmitations') }}
      </div>

      <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.microneedling.note.inCaseContraindication') }}
        </span>
      </additional-info-section>

      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.procedureCannotBeUsed') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.procedureIsNotRecommended') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.procedureIsAppliedTwice') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.procedureWithNeedles') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.microneedling.sensitiveSkin') }}
      </div>
      <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb15">
        <span slot="info-text">
          {{ $t('userGuide.microneedling.remember.medicalCondition') }}
        </span>
      </additional-info-section>

      <div id="contraindications" class="subsection__subtitle">
        {{ $t('userGuide.title.contraindications') }}
      </div>
      <div class="subsection__content subsection__content--with-indent mt15">
        <div class="subsection__content subsection__inner-text">
          {{ $t('userGuide.microneedling.beforeCreating') }}
        </div>

        <div class="subsection__content--line-height">
          •
          <i18n path="userGuide.microneedling.doNotUse1" tag="span">
            <b>{{ $t('userGuide.microneedling.pregnant') }}</b>
            <b>{{ $t('userGuide.microneedling.breastfeeding') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse2" tag="span">
            <b>{{ $t('userGuide.microneedling.skinCancer') }}</b>
          </i18n>
          <br />

          •
          <i18n path="userGuide.microneedling.doNotUse3" tag="span">
            <b>{{ $t('userGuide.microneedling.bleedingDisorder') }}</b>
            <b>{{ $t('userGuide.microneedling.takingAntiCoagulating') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse4" tag="span">
            <b>{{ $t('userGuide.microneedling.keloidScarring') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse5" tag="span">
            <b>{{ $t('userGuide.microneedling.oralRetinoids') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse6" tag="span">
            <b>{{ $t('userGuide.microneedling.photosensitivity') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse7" tag="span">
            <b>{{ $t('userGuide.microneedling.raisedMoles') }}</b>
            <b>{{ $t('userGuide.microneedling.warts') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse8" tag="span">
            <b>{{ $t('userGuide.microneedling.skinWound') }}</b>
            <b>{{ $t('userGuide.microneedling.brokenSkin') }}</b>
            <b>{{ $t('userGuide.microneedling.skabs') }}</b>
            <b>{{ $t('userGuide.microneedling.bleedingSkin') }}</b>
            <b>{{ $t('userGuide.microneedling.blisters') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse7" tag="span">
            <b>{{ $t('userGuide.microneedling.coldSores') }}</b>
            <b>{{ $t('userGuide.microneedling.proneToColdSores') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse2" tag="span">
            <b>{{ $t('userGuide.microneedling.proneToAcne') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse9" tag="span">
            <b>{{ $t('userGuide.microneedling.rashes') }}</b>
            <b>{{ $t('userGuide.microneedling.eczema') }}</b>
            <b>{{ $t('userGuide.microneedling.psoriasis') }}</b>
            <b>{{ $t('userGuide.microneedling.rosacea') }}</b>
            <b>{{ $t('userGuide.microneedling.skinIfections') }}</b>
            <b>{{ $t('userGuide.microneedling.fungalInfections') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUse10" tag="span">
            <b>{{ $t('userGuide.microneedling.microdermabrasion') }}</b>
            <b>{{ $t('userGuide.microneedling.botulinum') }}</b>
            <b>{{ $t('userGuide.microneedling.fillers') }}</b>
            <b>{{ $t('userGuide.microneedling.ipl') }} </b>
            <b>{{ $t('userGuide.microneedling.peels') }}</b>
            <b>{{ $t('userGuide.microneedling.cosmetic') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotPerform" tag="span">
            <b>{{ $t('userGuide.microneedling.sunBurnedSkin') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUseWhileUsing" tag="span">
            <b> {{ $t('userGuide.microneedling.tanningLotions') }}</b>
          </i18n>
          <br />
          •
          <i18n path="userGuide.microneedling.doNotUseInCase" tag="span">
            <b>{{ $t('userGuide.microneedling.metalAllergy') }}</b>
          </i18n>
          <br />
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'MicroneedlingGuide',
  components: { AdditionalInfoSection, OrderedList, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    microneedlingTreatmentDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/microneedling/microneedling-treatment-desktop-${this.locale}.jpg`);
    },
    microneedlingTreatmentMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/microneedling/microneedling-treatment-mobile-${this.locale}.jpg`);
    },
    settingTreatmentImage() {
      return require(`@/assets/images/dashboard/user-guide/microneedling/setting-treatment-desktop-${this.locale}.png`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';
</style>
