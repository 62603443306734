<template>
  <article class="article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.messageTemplates.pageAllows') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="howToAccessPage" class="subsection__subtitle">
          {{ $t('userGuide.title.howToAccessPage') }}
        </div>
        <ordered-list class="mb15">
          <template slot="list-items">
            <li>
              {{ $t('userGuide.messageTemplates.clickBurger') }}
            </li>
            <li>
              <i18n path="userGuide.messageTemplates.selectAndClickTemplate" tag="span">
                <b>{{ $t('userGuide.messageTemplates.messageTemplate') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>

        <div class="subsection__text">
          {{ $t('userGuide.messageTemplates.youCanSeeThreeTabs') }}
          {{ $t('userGuide.messageTemplates.marketingTemplates') }}
          <br />
          {{ $t('userGuide.messageTemplates.customCampaigns') }}
          <br />
          {{ $t('userGuide.messageTemplates.emailNotifications') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="marketingCampaigns" class="subsection__title">
        {{ $t('userGuide.title.marketingCampaigns') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.messageTemplates.marketingCampaigns') }}
        </div>

        <div class="subsection__inner-text">
          {{ $t('userGuide.messageTemplates.preparedThreeTypes') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.messageTemplates.note.modifiedVersion') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="customMarketing" class="subsection__title">
        {{ $t('userGuide.title.customMarketing') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.messageTemplates.createOwnCustom') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.messageTemplates.note.endOfCampaign') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="notifications" class="subsection__title">
        {{ $t('userGuide.title.notifications') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.messageTemplates.notificationsInclude') }}
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.questionnaireCompleted') }}</b>
          {{ $t('userGuide.messageTemplates.sentToPatients') }}
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.incompleteQuestionnaire') }}</b>
          {{ $t('userGuide.messageTemplates.sentToInactivePatients') }}
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.treatmentCompleted') }}</b>
          {{ $t('userGuide.messageTemplates.productsIncluded') }}
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.newTreatmentCreated') }}</b>
          {{ $t('userGuide.messageTemplates.treatmentWasCreated') }}
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.treatmentOffer') }}</b>
          <i18n path="userGuide.messageTemplates.treatmentOfferSent" tag="span">
            <b>{{ $t('userGuide.messageTemplates.label.webshopOfferPage') }}</b>
          </i18n>
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.renewOffer') }}</b>
          <i18n path="userGuide.messageTemplates.renewOfferSent" tag="span">
            <b>{{ $t('userGuide.messageTemplates.label.webshopOfferPage') }}</b>
          </i18n>
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.microneedlingTreatment') }}</b>
          {{ $t('userGuide.messageTemplates.microneedlingTreatmentSent') }}
        </div>

        <div class="subsection__inner-text">
          <b>{{ $t('userGuide.messageTemplates.microneedlingTreatmentOffer') }}</b>
          <i18n path="userGuide.messageTemplates.microneedlingTreatmentOfferSent" tag="span">
            <b>{{ $t('userGuide.messageTemplates.label.webshopOfferPage') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.messageTemplates.remember.modifyOrUpdate') }}
          </span>
        </additional-info-section>
      </div>
    </section>
  </article>
</template>

<script>
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'MessageTemplatesGuide',
  components: { AdditionalInfoSection, OrderedList },
  additionalInfoType: ADDITIONAL_INFO_TYPE
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';
</style>
