<template>
  <article class="loyalty-program-guide article">
    <section v-for="subsection in subsections" :key="subsection" class="article__subsection">
      <div :id="subsection" class="subsection__title">{{ subsection }}</div>
      <div class="subsection__content subsection__content--with-indent">
        <div v-for="{ value, translation } in glossaryTermsConfig[subsection]" :key="value">
          <div :id="value" class="subsection__subtitle">{{ translation }}</div>
          <glossary-term-description :term-value="value" />
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import GlossaryTermDescription from '@/modules/dashboard/components/user-guide/articles/GlossaryTermDescription';

export default {
  name: 'Glossary',
  components: { GlossaryTermDescription },
  props: {
    subsections: {
      type: Array,
      required: true
    },
    glossaryTermsConfig: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';
</style>
