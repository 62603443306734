<template>
  <article class="quick-label-print article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.quickLabelPrint.canDownload') }}
        </div>
      </div>

      <img
        class="subsection__centered-block quick-label-print__pdf-label-image"
        :src="pdfLabelImage"
        alt="Pdf label document"
      />
    </section>

    <section class="article__subsection">
      <div id="accessPdfFromDashboard" class="subsection__title">
        {{ $t('userGuide.title.accessPdfFromDashboard') }}
      </div>
      <div class="subsection__content">
        <img
          class="subsection__image mb15"
          :src="quickLabelPrintButtonImage"
          alt="Quick label print button"
        />

        <ordered-list class="mb15">
          <template slot="list-items">
            <li>
              {{ $t('userGuide.quickLabelPrint.goToDashboard') }}
            </li>
            <li>
              <i18n path="userGuide.quickLabelPrint.clickQuickLabel" tag="span">
                <b>{{ $t('userGuide.quickLabelPrint.quickLabelPrint') }}</b>
              </i18n>
            </li>
            <li>
              {{ $t('userGuide.quickLabelPrint.selectTreatment') }}
            </li>
            <li>
              <i18n path="userGuide.quickLabelPrint.clickQuickLabelPrint" tag="span">
                <b>{{ $t('userGuide.quickLabelPrint.quickLabelPrint') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.quickLabelPrint.tip.findSpecificTreatment') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="accessPdfFromPatientCard" class="subsection__title">
        {{ $t('userGuide.title.accessPdfFromPatientCard') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          <b>1.</b>
          <i18n path="userGuide.quickLabelPrint.youCanDownload" tag="span">
            <b>{{ $t('userGuide.quickLabelPrint.printLabel') }}</b>
          </i18n>
        </div>

        <div class="subsection__inner-text">
          <b>2.</b>
          <i18n path="userGuide.quickLabelPrint.youCanPrint" tag="span">
            <b>{{ $t('userGuide.quickLabelPrint.printLabel') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP">
          <span slot="info-text">
            <i18n path="userGuide.quickLabelPrint.tip.allowYourAssistant" tag="span">
              <b>{{ $t('userGuide.quickLabelPrint.quickLabelPrint') }}</b>
            </i18n>
          </span>
        </additional-info-section>
      </div>
    </section>
  </article>
</template>

<script>
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

export default {
  name: 'QuickLabelPrintGuide',
  components: { AdditionalInfoSection, OrderedList },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    pdfLabelImage() {
      return require(`@/assets/images/dashboard/user-guide/quick-label-print/pdf-label-${this.locale}.jpg`);
    },
    quickLabelPrintButtonImage() {
      return require(`@/assets/images/dashboard/user-guide/quick-label-print/quick-label-print-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';

.quick-label-print {
  &__pdf-label-image {
    min-height: 439px;
  }
}

@media (max-width: 767px) {
  .quick-label-print {
    &__pdf-label-image {
      min-height: 369px;
    }
  }
}
</style>
