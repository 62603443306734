<template>
  <section class="info__section content__note flex" :class="infoTypeClass">
    <div class="section__icon-container inline-block">
      <div class="section__icon custom-icon cursor-default" :class="infoIconClass"></div>
    </div>
    <div class="inline-block">
      <span class="bold inline-block__label">{{ $t(`userGuide.label.${infoPrefix}`) }}</span>
      <slot name="info-text"></slot>
    </div>
  </section>
</template>

<script>
import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

const INFO_ICON = {
  [ADDITIONAL_INFO_TYPE.TIP]: 'icon-check-blue',
  [ADDITIONAL_INFO_TYPE.NOTE]: 'icon-exclamation-mark',
  [ADDITIONAL_INFO_TYPE.REMEMBER]: 'icon-exclamation-mark-green'
};

const INFO_PREFIX_KEY_POSTFIX = {
  [ADDITIONAL_INFO_TYPE.TIP]: 'tip',
  [ADDITIONAL_INFO_TYPE.NOTE]: 'note',
  [ADDITIONAL_INFO_TYPE.REMEMBER]: 'remember'
};

export default {
  name: 'AdditionalInfoSection',
  props: {
    infoType: {
      type: String,
      required: true
    }
  },
  computed: {
    infoTypeClass() {
      return {
        info__tip: this.infoType === INFO_PREFIX_KEY_POSTFIX[ADDITIONAL_INFO_TYPE.TIP],
        info__note: this.infoType === INFO_PREFIX_KEY_POSTFIX[ADDITIONAL_INFO_TYPE.NOTE],
        info__remember: this.infoType === INFO_PREFIX_KEY_POSTFIX[ADDITIONAL_INFO_TYPE.REMEMBER]
      };
    },
    infoIconClass() {
      return INFO_ICON[this.infoType];
    },
    infoPrefix() {
      return INFO_PREFIX_KEY_POSTFIX[this.infoType];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons';

.info {
  &__section {
    width: 100%;
    max-width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 13px;
    color: $text-color;
  }

  &__note {
    background-color: $island-spice;
  }

  &__tip {
    background-color: $alice-blue;
  }

  &__remember {
    background-color: $sugar-cane;
  }
}

.inline-block {
  &__label {
    margin-right: 3px;
  }
}

.section {
  &__icon-container {
    width: 9px;
    margin-right: 10px;
  }

  &__icon {
    margin-top: -1px;
  }
}

@media (max-width: 767px) {
  .info {
    &__section {
      font-size: 11px;
    }
  }
}
</style>
