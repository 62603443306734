import { isMobileDevice } from '@/modules/dashboard/utils';

const SCROLL_TOP_OFFSET = 135;

const userGuideSidebarMixin = {
  mounted() {
    if (isMobileDevice()) {
      return;
    }

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    if (isMobileDevice()) {
      return;
    }

    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const { sidebar } = this.$refs;
      const scrollTop = window.pageYOffset;

      if (scrollTop > SCROLL_TOP_OFFSET) {
        sidebar.classList.add('sidebar--fixed');
      } else {
        sidebar.classList.remove('sidebar--fixed');
      }
    },
    goToUserGuideMainPage() {
      this.$router.push({ name: 'UserGuideMainPage' });
    }
  }
};

export default userGuideSidebarMixin;
