<template>
  <section class="article-section relative">
    <article-sidebar
      :subsections="subsections"
      :selected-subsection="selectedSubsection"
      :is-subsection-key="!isGlossaryArticle"
      @select-subsection="selectSubsection"
    />
    <article-template ref="template" :article-name="articleName">
      <template slot="content">
        <component
          :is="articleComponentName"
          :subsections="subsections"
          :glossary-terms-config="glossaryTermsConfig"
          :locale="currentLocale"
        ></component>
      </template>
    </article-template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { debounce, findLast, first } from 'lodash';

import ArticleSidebar from '@/modules/dashboard/components/user-guide/ArticleSidebar';
import ArticleTemplate from '@/modules/dashboard/components/user-guide/ArticleTemplate';
import AboutSkinxs from '@/modules/dashboard/components/user-guide/articles/AboutSkinxs';
import PersonalAccount from '@/modules/dashboard/components/user-guide/articles/PersonalAccount';
import DashboardGuide from '@/modules/dashboard/components/user-guide/articles/DashboardGuide';
import Patient from '@/modules/dashboard/components/user-guide/articles/Patient';
import InvitingPatients from '@/modules/dashboard/components/user-guide/articles/InvitingPatients';
import QuestionnaireGuide from '@/modules/dashboard/components/user-guide/articles/QuestionnaireGuide';
import PhotoAnalysisGuide from '@/modules/dashboard/components/user-guide/articles/PhotoAnalysisGuide';
import SkinDiagnosticGuide from '@/modules/dashboard/components/user-guide/articles/SkinDiagnosticGuide';
import TreatmentGuide from '@/modules/dashboard/components/user-guide/articles/TreatmentGuide';
import QuickLabelPrintGuide from '@/modules/dashboard/components/user-guide/articles/QuickLabelPrintGuide';
import MessageTemplatesGuide from '@/modules/dashboard/components/user-guide/articles/MessageTemplatesGuide';
import NotificationsGuide from '@/modules/dashboard/components/user-guide/articles/NotificationsGuide';
import LoyaltyProgramGuide from '@/modules/dashboard/components/user-guide/articles/LoyaltyProgramGuide';
import Glossary from '@/modules/dashboard/components/user-guide/articles/Glossary';
import OnlineConciergeService from '@/modules/dashboard/components/user-guide/articles/OnlineConciergeService';
import MicroneedlingGuide from '@/modules/dashboard/components/user-guide/articles/MicroneedlingGuide';
import PriceSettingsGuide from '@/modules/dashboard/components/user-guide/articles/PriceSettingsGuide';
import TreatmentsDirectoryGuide from '@/modules/dashboard/components/user-guide/articles/TreatmentsDirectoryGuide';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

import {
  USER_GUIDE_ARTICLE_NAME,
  ARTICLE_COMPONENT_CONFIG,
  GLOSSARY_TERMS_KEY_POSTFIXES,
  SIDEBAR_SUBSECTION,
  SCROLL_SUBSECTION_OFFSET,
  SCROLL_SUBSECTION_OFFSET_MOBILE
} from '@/modules/dashboard/api/user-guide';

const SECTION_SCROLL_DEBOUNCE_TIME = 100;
const SUPPORTED_LOCALE = ['fr', 'ru', 'en'];
const DEFAULT_LOCALE = 'en';

export default {
  name: 'ArticleSection',
  components: {
    Glossary,
    LoyaltyProgramGuide,
    TreatmentGuide,
    QuickLabelPrintGuide,
    NotificationsGuide,
    MessageTemplatesGuide,
    SkinDiagnosticGuide,
    PhotoAnalysisGuide,
    QuestionnaireGuide,
    InvitingPatients,
    DashboardGuide,
    ArticleSidebar,
    ArticleTemplate,
    AboutSkinxs,
    PersonalAccount,
    Patient,
    OnlineConciergeService,
    MicroneedlingGuide,
    PriceSettingsGuide,
    TreatmentsDirectoryGuide
  },
  props: {
    articleName: {
      type: String,
      required: true
    },
    scrollId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedSubsection: '',
      glossarySubsections: [],
      glossaryTermsConfig: {}
    };
  },
  computed: {
    ...mapGetters({
      locale: rootTypes.getters.GET_LOCALE
    }),
    currentLocale() {
      return SUPPORTED_LOCALE.includes(this.locale) ? this.locale : DEFAULT_LOCALE;
    },
    articleComponentName() {
      return ARTICLE_COMPONENT_CONFIG[this.articleName].name;
    },
    isGlossaryArticle() {
      return this.articleName === USER_GUIDE_ARTICLE_NAME.GLOSSARY;
    },
    subsections() {
      if (this.isGlossaryArticle) {
        return this.glossarySubsections;
      }

      return SIDEBAR_SUBSECTION[this.articleName] || [''];
    }
  },
  mounted() {
    if (this.isGlossaryArticle) {
      this.setGlossarySubsectionsConfig();
      this.sortGlossaryTerms();
    }

    [this.selectedSubsection] = this.subsections;

    if (this.scrollId) {
      const offset = isMobileDevice() ? SCROLL_SUBSECTION_OFFSET_MOBILE : SCROLL_SUBSECTION_OFFSET;

      this.$scrollTo(`#${this.scrollId}`, { offset, cancelable: false });
    }

    if (isMobileDevice()) {
      return;
    }

    window.addEventListener('scroll', this.debounceHandleSectionsScroll);
  },
  beforeDestroy() {
    if (isMobileDevice()) {
      return;
    }

    window.removeEventListener('scroll', this.debounceHandleSectionsScroll);
  },
  methods: {
    setGlossarySubsectionsConfig() {
      const subsectionsSet = new Set();
      const subsectionsTermsConfig = {};

      GLOSSARY_TERMS_KEY_POSTFIXES.forEach(termPostfix => {
        const termTranslation = this.$t(`userGuide.title.${termPostfix}`);
        const termLetter = termTranslation[0].toUpperCase();
        const subsectionTerms = subsectionsTermsConfig[termLetter] || [];

        subsectionsSet.add(termLetter);
        subsectionsTermsConfig[termLetter] = [
          ...subsectionTerms,
          { value: termPostfix, translation: termTranslation }
        ];
      });

      this.glossarySubsections = [...subsectionsSet].sort();
      this.glossaryTermsConfig = subsectionsTermsConfig;
    },
    sortGlossaryTerms() {
      this.glossaryTermsConfig = Object.entries(this.glossaryTermsConfig).reduce(
        (config, [letter, terms]) => ({
          ...config,
          [letter]: terms.sort((firstTerm, secondTerm) =>
            firstTerm.translation.toLowerCase().localeCompare(secondTerm.translation.toLowerCase())
          )
        }),
        {}
      );
    },
    selectSubsection(subsection) {
      this.selectedSubsection = subsection;
    },
    debounceHandleSectionsScroll: debounce(
      function handleSectionsScroll() {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const fromTop = scrollTop + 100;

        const sections = [...this.$refs.template.$el.querySelectorAll('.subsection__title')];

        const currentSection =
          findLast(sections, section => section.offsetTop < fromTop) || first(sections);

        if (!currentSection) {
          return;
        }

        this.selectedSubsection = currentSection.id;
      },
      SECTION_SCROLL_DEBOUNCE_TIME,
      {
        leading: true,
        trailing: false,
        maxWait: SECTION_SCROLL_DEBOUNCE_TIME
      }
    )
  },
  metaInfo() {
    const title =
      this.$t(`userGuide.title.${ARTICLE_COMPONENT_CONFIG[this.articleName].title}`) ||
      'User Guide';

    return {
      title,
      titleTemplate: 'skinXs - %s'
    };
  }
};
</script>

<style lang="scss" scoped></style>
