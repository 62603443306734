<template>
  <article class="skin-diagnostic-guide article">
    <section class="article__subsection">
      <div id="diagnosticCreation" class="subsection__title">
        {{ $t('userGuide.title.diagnosticCreation') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.skinDiagnostic.thanksToPlatform') }}
        </div>
        <img
          :src="skinDiagnosticResultImage"
          alt="Skin Diagnostic Result"
          class="skin-diagnostic-guide__skin-diagnostic-result-image subsection__text subsection__centered-block"
        />
        <div class="subsection__text">
          {{ $t('userGuide.skinDiagnostic.youCanGenerateDiagnostic') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div
          id="questionnaireBasedDiagnostic"
          class="subsection__subtitle subsection__subtitle--text-color"
        >
          {{ $t('userGuide.title.questionnaireBasedDiagnostic') }}
        </div>
        <div id="howDiagnosticQuestionnaireCreated" class="subsection__subtitle">
          {{ $t('userGuide.title.howDiagnosticQuestionnaireCreated') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.skinDiagnostic.onlineQuestionnaire') }}
          <br />
          {{ $t('userGuide.skinDiagnostic.aNewQuestionnaire') }}
        </div>
        <div id="waysToViewQuestionnaire" class="subsection__subtitle">
          {{ $t('userGuide.title.waysToViewQuestionnaire') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.skinDiagnostic.fromDashboard') }}
          <br />
          {{ $t('userGuide.skinDiagnostic.fromCard') }}
          <br />
          {{ $t('userGuide.skinDiagnostic.fromEmail') }}
        </div>
        <div
          id="diagnosticBasedOnPhotoAnalysis"
          class="subsection__subtitle subsection__subtitle--text-color"
        >
          {{ $t('userGuide.title.diagnosticBasedOnPhotoAnalysis') }}
        </div>
        <div id="howDiagnosticPhotoCreated" class="subsection__subtitle">
          {{ $t('userGuide.title.howDiagnosticPhotoCreated') }}
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.skinDiagnostic.youCanGenerateSkin" tag="span">
            <router-link
              class="subsection__link"
              :to="$options.skinDiagnosticPageLinks.PHOTO_ANALYSIS_PAGE"
            >
              {{ $t('userGuide.skinDiagnostic.photoAnalysis') }}
            </router-link>
          </i18n>
        </div>
        <div id="waysToViewPhoto" class="subsection__subtitle">
          {{ $t('userGuide.title.waysToViewPhoto') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.skinDiagnostic.viewDiagnostic') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="diagnosticHistory" class="subsection__title">
        {{ $t('userGuide.title.diagnosticHistory') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          <i18n path="userGuide.skinDiagnostic.inTheDiagnosticsTab" tag="span">
            <router-link
              class="subsection__link"
              :to="$options.skinDiagnosticPageLinks.PATIENT_PAGE_DIAGNOSTIC_HISTORY_SUBSECTION"
            >
              {{ $t('userGuide.skinDiagnostic.diagnostics') }}
            </router-link>
          </i18n>
        </div>

        <div class="subsection__content subsection__content--with-indent">
          <div id="diagnosticsPhoto" class="subsection__subtitle subsection__subtitle--text-color">
            {{ $t('userGuide.title.diagnosticsPhoto') }}
          </div>
          <div class="subsection__text">
            {{ $t('userGuide.skinDiagnostic.attachedPhoto') }}
            <br />
            {{ $t('userGuide.skinDiagnostic.patientUploaded') }}
            <br />
            {{ $t('userGuide.skinDiagnostic.patientAgreed') }}
          </div>

          <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
            <span slot="info-text">
              {{ $t('userGuide.skinDiagnostic.note.noPhoto') }}
            </span>
          </additional-info-section>

          <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
            <span slot="info-text">
              <i18n path="userGuide.skinDiagnostic.tip.clickCreateTreatment" tag="span">
                <b>{{ $t('userGuide.skinDiagnostic.tip.createTreatment') }}</b>
              </i18n>
            </span>
          </additional-info-section>

          <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
            <span slot="info-text">
              <i18n path="userGuide.skinDiagnostic.note.cannotDelete" tag="span">
                <router-link
                  class="subsection__link"
                  :to="$options.skinDiagnosticPageLinks.TREATMENT_PAGE"
                >
                  {{ $t('userGuide.skinDiagnostic.note.treatment') }}
                </router-link>
              </i18n>
            </span>
          </additional-info-section>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="dysfunctions" class="subsection__title">
        {{ $t('userGuide.title.dysfunctions') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.skinDiagnostic.diagnosticContains') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.skinDiagnostic.skinDisfunctions') }}
          <template v-for="{ titleKeyPostfix, color } in $options.dysfunctions">
            <div :key="titleKeyPostfix" class="dysfunction__row">
              <span class="dysfunction__circle" :style="{ backgroundColor: color }"></span>
              {{ $t(`userGuide.skinDiagnostic.${titleKeyPostfix}`) }}
            </div>
          </template>
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.skinDiagnostic.weProvide') }}
        </div>
        <img
          :src="skinRednessResultImage"
          alt="Skin Redness Result"
          class="skin-diagnostic-guide__skin-redness-result-image subsection__text subsection__centered-block"
        />
        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.skinDiagnostic.tip.tapTheRow') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            <i18n path="userGuide.skinDiagnostic.note.visitSite" tag="span">
              <a
                href="https://www.universkin.com/your-skin"
                target="_blank"
                class="subsection__link"
              >
                {{ $t('userGuide.skinDiagnostic.note.site') }}
              </a>
            </i18n>
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="pdfSummary" class="subsection__title">
        {{ $t('userGuide.title.pdfSummary') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          <i18n path="userGuide.skinDiagnostic.clickPdf" tag="span">
            <b>{{ $t('userGuide.skinDiagnostic.pdfSummary') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.skinDiagnostic.note.pdfSummary') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.skinDiagnostic.note.patientCompletes') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="anomaliesAndIssues" class="subsection__title">
        {{ $t('userGuide.title.anomaliesAndIssues') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.skinDiagnostic.specificCases') }}
          <br />
          {{ $t('userGuide.skinDiagnostic.duringAnalysis') }}
        </div>
        <img
          :src="anomalySectionImage"
          alt="Anomaly"
          class="skin-diagnostic-guide__anomaly-image subsection__text subsection__centered-block"
        />
        <div class="subsection__inner-text">
          {{ $t('userGuide.skinDiagnostic.listOfIssues') }}
        </div>
        <div class="subsection__text">
          <i18n path="userGuide.skinDiagnostic.proceedDiagnostic" tag="span">
            <b>{{ $t('userGuide.skinDiagnostic.goToDiagnostic') }}</b>
            <b>{{ $t('userGuide.skinDiagnostic.next') }}</b>
            <b>{{ $t('userGuide.skinDiagnostic.validated') }}</b>
          </i18n>
        </div>
        <div class="subsection__content subsection__content--with-indent">
          <div id="typesOfAnomalies" class="subsection__subtitle">
            {{ $t('userGuide.title.typesOfAnomalies') }}
          </div>
          <div class="subsection__inner-text">
            {{ $t('userGuide.skinDiagnostic.dependingOnPatient') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly1') }}</b>
            {{ $t('userGuide.skinDiagnostic.discordances') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly2') }}</b>
            {{ $t('userGuide.skinDiagnostic.incoherence') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly3') }}</b>
            {{ $t('userGuide.skinDiagnostic.problemSkin') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly4') }}</b>
            {{ $t('userGuide.skinDiagnostic.skinDehydrated') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly5') }}</b>
            {{ $t('userGuide.skinDiagnostic.patientHaving') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly6') }}</b>
            {{ $t('userGuide.skinDiagnostic.dryAndOily') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.anomaly7') }}</b>
            {{ $t('userGuide.skinDiagnostic.acneAndAge') }}
          </div>

          <div id="typesOfIssues" class="subsection__subtitle">
            {{ $t('userGuide.title.typesOfIssues') }}
          </div>
          <div class="subsection__inner-text">
            {{ $t('userGuide.skinDiagnostic.dependingThePatient') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.issue1') }}</b>
            {{ $t('userGuide.skinDiagnostic.allergyAndCosmetic') }}

            <br />
            <b>{{ $t('userGuide.skinDiagnostic.issue2') }}</b>
            {{ $t('userGuide.skinDiagnostic.allergyNotListed') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.issue3') }}</b>
            {{ $t('userGuide.skinDiagnostic.medicalConditions') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.issue4') }}</b>
            {{ $t('userGuide.skinDiagnostic.medications') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.issue5') }}</b>
            {{ $t('userGuide.skinDiagnostic.skincare') }}
            <br />
            <b>{{ $t('userGuide.skinDiagnostic.issue6') }}</b>
            {{ $t('userGuide.skinDiagnostic.sensitiveSkin') }}
          </div>
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="algorithmRecommendations" class="subsection__title">
        {{ $t('userGuide.title.algorithmRecommendations') }}
      </div>
      <div class="subsection__content">
        <i18n path="userGuide.skinDiagnostic.treatmentFormula" tag="span">
          <router-link
            class="subsection__link"
            :to="$options.skinDiagnosticPageLinks.TREATMENT_PAGE"
          >
            {{ $t('userGuide.skinDiagnostic.treatment') }}
          </router-link>
        </i18n>
      </div>
    </section>
  </article>
</template>

<script>
import ArticleSectionRoute from '@/modules/dashboard/components/user-guide/articles/ArticleSectionRoute';

import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import { USER_GUIDE_ARTICLE_NAME } from '@/modules/dashboard/api/user-guide';

import {
  ADDITIONAL_INFO_TYPE,
  ACTIVES,
  DYSFUNCTIONS_COLORS
} from '@/modules/dashboard/api/constants';

const DYSFUNCTIONS = [
  { titleKeyPostfix: 'oxidativeStress', color: DYSFUNCTIONS_COLORS[ACTIVES.OXIDATIVE_STRESS] },
  { titleKeyPostfix: 'skinAppearance', color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_APPEARANCE] },
  { titleKeyPostfix: 'skinRedness', color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_REDNESS] },
  { titleKeyPostfix: 'skinDryness', color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_DRYNESS] },
  { titleKeyPostfix: 'skinOiliness', color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_OILINESS] },
  { titleKeyPostfix: 'skinTexture', color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_TEXTURE] },
  { titleKeyPostfix: 'pimples', color: DYSFUNCTIONS_COLORS[ACTIVES.PIMPLES] },
  { titleKeyPostfix: 'skinPigmentation', color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_PIGMENTATION] }
];

const SKIN_DIAGNOSTIC_PAGE_LINKS = {
  PHOTO_ANALYSIS_PAGE: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS
  }),
  PATIENT_PAGE_DIAGNOSTIC_HISTORY_SUBSECTION: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.PATIENT,
    scrollId: 'diagnostics-history'
  }),
  TREATMENT_PAGE: new ArticleSectionRoute({ articleName: USER_GUIDE_ARTICLE_NAME.TREATMENT })
};

export default {
  name: 'SkinDiagnosticGuide',
  components: { AdditionalInfoSection },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  skinDiagnosticPageLinks: SKIN_DIAGNOSTIC_PAGE_LINKS,
  dysfunctions: DYSFUNCTIONS,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    anomalySectionImage() {
      return require(`@/assets/images/dashboard/user-guide/skin-diagnostic/anomaly-${this.locale}.jpg`);
    },
    skinDiagnosticResultImage() {
      return require(`@/assets/images/dashboard/user-guide/skin-diagnostic/skin-diagnostic-result-${this.locale}.jpg`);
    },
    skinRednessResultImage() {
      return require(`@/assets/images/dashboard/user-guide/skin-diagnostic/skin-redness-result-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';

.dysfunction {
  &__circle {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;
    border-radius: 50%;
  }
}

.skin-diagnostic-guide {
  &__skin-diagnostic-result-image {
    width: 300px;
    min-height: 192px;
  }

  &__skin-redness-result-image {
    width: 426px;
    min-height: 185px;
  }

  &__anomaly-image {
    width: 300px;
    min-height: 257px;
  }
}

@media (max-width: 767px) {
  .skin-diagnostic-guide {
    &__skin-diagnostic-result-image {
      min-height: 169px;
    }

    &__skin-redness-result-image {
      min-height: 115px;
    }

    &__anomaly-image {
      min-height: 226px;
    }
  }
}
</style>
