<template>
  <section>
    <div class="tabs__tab-names">
      <slot name="tabs">
        <span
          class="tab__name"
          :class="{ 'tab__name--selected': getIsTabSelected($options.tabs.DESKTOP) }"
          @click="selectTab($options.tabs.DESKTOP)"
        >
          {{ $t('userGuide.action.desktop') }}
        </span>
        <span
          class="tab__name"
          :class="{ 'tab__name--selected': getIsTabSelected($options.tabs.MOBILE) }"
          @click="selectTab($options.tabs.MOBILE)"
        >
          {{ $t('userGuide.action.mobile') }}
        </span>
      </slot>
    </div>
    <section class="tabs__content-container flex--center--center">
      <slot v-if="isDesktopTabSelected" name="desktop-content"></slot>
      <slot v-else name="mobile-content" class="tabs__content"></slot>
    </section>
  </section>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

const TABS = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE'
};

export default {
  name: 'UserGuideTabs',
  tabs: TABS,
  props: {
    isOneOptionOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedTab: TABS.DESKTOP
    };
  },
  computed: {
    isDesktopTabSelected() {
      return this.selectedTab === TABS.DESKTOP;
    }
  },
  created() {
    this.selectedTab = isMobileDevice() && !this.isOneOptionOnly ? TABS.MOBILE : TABS.DESKTOP;
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    getIsTabSelected(tabName) {
      return this.selectedTab === tabName;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.tabs {
  &__tab-names {
    margin-left: 20px;
    padding-bottom: 4px;
    font-weight: 600;
    font-size: 13px;
    color: $grey-color;
  }

  &__content-container {
    width: 100%;
    margin-top: -1px;
    padding: 20px 35px;
    min-height: 140px;

    border: 1px solid $gainsboro-color;
    border-radius: 10px;
  }
}

.tab {
  &__name {
    padding-bottom: 5px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &--selected {
      color: $text-color;
      border-bottom: 1px solid $orange-color;
    }
  }
}

@media (max-width: 1024px) {
  .tabs {
    &__content-container {
      padding: 20px;
    }
  }
}
</style>
