<template>
  <article class="concierge-service-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.onlineConciergeService.skinxsAccount') }}
          <br />
          {{ $t('userGuide.onlineConciergeService.skinxsAccountAdvanced') }}
        </div>

        <div class="subsection__text">
          {{ $t('userGuide.onlineConciergeService.contactUniverskin') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="sendTreatmentOffer" class="subsection__title">
        {{ $t('userGuide.title.sendTreatmentOffer') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.onlineConciergeService.accountUpgraded') }}
      </div>
      <div class="subsection__content subsection__content--with-indent">
        <div id="howToSendTreatmentOffer" class="subsection__subtitle ">
          {{ $t('userGuide.title.howToSendTreatmentOffer') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>
              {{ $t('userGuide.onlineConciergeService.goToPatientCard') }}
            </li>
            <li>
              {{ $t('userGuide.onlineConciergeService.setUpTreatment') }}
            </li>

            <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
              <span slot="info-text">
                {{ $t('userGuide.onlineConciergeService.note.faceSerum') }}
              </span>
            </additional-info-section>
            <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
              <span slot="info-text">
                {{ $t('userGuide.onlineConciergeService.note.canNotSendOffer') }}
              </span>
            </additional-info-section>

            <li>
              <i18n path="userGuide.onlineConciergeService.findAndClick" tag="span">
                <b>{{ $t('userGuide.onlineConciergeService.sendOffer') }}</b>
              </i18n>
            </li>

            <user-guide-tabs class="mb20">
              <div slot="desktop-content">
                <img
                  :src="sendOfferButtonDesktopImage"
                  alt="Send offer button on dashboard"
                  class="user-guide-tabs__image"
                />
              </div>
              <div slot="mobile-content">
                <img
                  :src="sendOfferButtonMobileImage"
                  alt="Send offer button on dashboard"
                  class="user-guide-tabs__image-mob"
                />
              </div>
            </user-guide-tabs>

            <li>{{ $t('userGuide.onlineConciergeService.checkTreatment') }}</li>

            <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
              <span slot="info-text">
                {{ $t('userGuide.onlineConciergeService.customizeTreatmentOffer') }}
              </span>
            </additional-info-section>

            <li>
              <i18n path="userGuide.onlineConciergeService.clickButton" tag="span">
                <b>{{ $t('userGuide.onlineConciergeService.sendTreatmentOffer') }}</b>
              </i18n>
            </li>
          </template>
        </ordered-list>
      </div>

      <div id="treatmentsHistory" class="subsection__subtitle">
        {{ $t('userGuide.title.treatmentsHistory') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.onlineConciergeService.treatmentOfferSent') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.onlineConciergeService.patientPlacesOrder') }}
      </div>

      <user-guide-tabs class="mb20">
        <div slot="desktop-content">
          <img
            :src="treatmentsHistoryDesktopImage"
            alt="Treatments history"
            class="user-guide-tabs__image"
          />
        </div>
        <div slot="mobile-content">
          <slider
            v-model="treatmentActiveSlide"
            :images="treatmentsHistoryImages"
            image-text="Treatments history"
            is-mobile-device
          ></slider>
        </div>
      </user-guide-tabs>
    </section>

    <section class="article__subsection">
      <div id="webshopOfferPage" class="subsection__title">
        {{ $t('userGuide.title.webshopOfferPage') }}
      </div>
      <div class="subsection__content subsection__inner-text">
        {{ $t('userGuide.onlineConciergeService.offerPage') }}
      </div>

      <user-guide-tabs class="mb20">
        <div slot="desktop-content">
          <slider
            v-model="webshopDesktopActiveSlide"
            :images="webshopDesktopImages"
            image-text="Webshop offer page"
          ></slider>
        </div>
        <div slot="mobile-content">
          <slider
            v-model="webshopMobileActiveSlide"
            :images="webshopMobileImages"
            image-text="Webshop offer page"
            is-mobile-device
          ></slider>
        </div>
      </user-guide-tabs>
    </section>

    <section class="article__subsection">
      <div id="order" class="subsection__title">
        {{ $t('userGuide.title.order') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.onlineConciergeService.afterPayment') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.onlineConciergeService.note.calculatedTreatment') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="consultationFee" class="subsection__title">
        {{ $t('userGuide.title.consultationFee') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          <i18n path="userGuide.onlineConciergeService.asDescribed" tag="span">
            <b>{{ $t('userGuide.onlineConciergeService.serviceAgreement') }}</b>
            <b>{{ $t('userGuide.onlineConciergeService.service') }}</b>
            <b>{{ $t('userGuide.onlineConciergeService.onlineConsultationFee') }}</b>
          </i18n>
        </div>
        <div class="subsection__content subsection__content--with-indent">
          <div id="howToAccessOnlineCosmetovigilance" class="subsection__subtitle">
            {{ $t('userGuide.title.howToAccessOnlineCosmetovigilance') }}
          </div>

          <ordered-list class="mb20">
            <template slot="list-items">
              <li>
                {{ $t('userGuide.onlineConciergeService.clickBurgerMenu') }}
              </li>
              <li>
                <i18n path="userGuide.onlineConciergeService.selectAndClick" tag="span">
                  <b>{{ $t('userGuide.onlineConciergeService.onlineConsultationFee') }}</b>
                </i18n>
              </li>
            </template>
          </ordered-list>
        </div>
        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="consultationFeeDesktopImage"
              alt="Consultation Fee"
              class="user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="consultationFeeMobileImage"
              alt="Consultation Fee"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__text">
          {{ $t('userGuide.onlineConciergeService.reviewSentOffers') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.onlineConciergeService.note.calculatedFee') }}
          </span>
        </additional-info-section>

        <div class="subsection__text">
          <i18n path="userGuide.onlineConciergeService.redeemConsultationFee" tag="span">
            <b>{{ $t('userGuide.onlineConciergeService.sendInvoice') }}</b>
          </i18n>
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.onlineConciergeService.note.invoiceDocument') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.onlineConciergeService.tip.findYourDistributor') }}
          </span>
        </additional-info-section>
      </div>
    </section>
  </article>
</template>

<script>
import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import Slider from '@/modules/dashboard/components/user-guide/Slider';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';

import WebshopFirstSlideDesktopEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/1-en.jpg';
import WebshopSecondSlideDesktopEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/2-en.jpg';
import WebshopThirdSlideDesktopEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/3-en.jpg';
import WebshopFourthSlideDesktopEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/4-en.jpg';
import WebshopFifthSlideDesktopEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/5-en.jpg';

import WebshopFirstSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/1-en.jpg';
import WebshopSecondSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/2-en.jpg';
import WebshopThirdSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/3-en.jpg';
import WebshopFourthSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/4-en.jpg';
import WebshopFifthSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/5-en.jpg';

import WebshopFirstSlideDesktopFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/1-fr.jpg';
import WebshopSecondSlideDesktopFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/2-fr.jpg';
import WebshopThirdSlideDesktopFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/3-fr.jpg';
import WebshopFourthSlideDesktopFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/4-fr.jpg';
import WebshopFifthSlideDesktopFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/desktop/5-fr.jpg';

import WebshopFirstSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/1-fr.jpg';
import WebshopSecondSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/2-fr.jpg';
import WebshopThirdSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/3-fr.jpg';
import WebshopFourthSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/4-fr.jpg';
import WebshopFifthSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/webshop/mobile/5-fr.jpg';

import TreatmentsHistoryFirstSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/1-en.jpg';
import TreatmentsHistorySecondSlideMobileEn from '@/assets/images/dashboard/user-guide/concierge-service/2-en.jpg';

import TreatmentsHistoryFirstSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/1-fr.jpg';
import TreatmentsHistorySecondSlideMobileFr from '@/assets/images/dashboard/user-guide/concierge-service/2-fr.jpg';

import TreatmentsHistoryFirstSlideMobileRu from '@/assets/images/dashboard/user-guide/concierge-service/1-ru.jpg';
import TreatmentsHistorySecondSlideMobileRu from '@/assets/images/dashboard/user-guide/concierge-service/2-ru.jpg';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';

const WEBSHOP_IMAGES_DESKTOP = {
  en: [
    WebshopFirstSlideDesktopEn,
    WebshopSecondSlideDesktopEn,
    WebshopThirdSlideDesktopEn,
    WebshopFourthSlideDesktopEn,
    WebshopFifthSlideDesktopEn
  ],
  fr: [
    WebshopFirstSlideDesktopFr,
    WebshopSecondSlideDesktopFr,
    WebshopThirdSlideDesktopFr,
    WebshopFourthSlideDesktopFr,
    WebshopFifthSlideDesktopFr
  ]
};

const WEBSHOP_IMAGES_MOBILE = {
  en: [
    WebshopFirstSlideMobileEn,
    WebshopSecondSlideMobileEn,
    WebshopThirdSlideMobileEn,
    WebshopFourthSlideMobileEn,
    WebshopFifthSlideMobileEn
  ],
  fr: [
    WebshopFirstSlideMobileFr,
    WebshopSecondSlideMobileFr,
    WebshopThirdSlideMobileFr,
    WebshopFourthSlideMobileFr,
    WebshopFifthSlideMobileFr
  ]
};

const TREATMENTS_HISTORY_MOBILE_IMAGES = {
  en: [TreatmentsHistoryFirstSlideMobileEn, TreatmentsHistorySecondSlideMobileEn],
  fr: [TreatmentsHistoryFirstSlideMobileFr, TreatmentsHistorySecondSlideMobileFr],
  ru: [TreatmentsHistoryFirstSlideMobileRu, TreatmentsHistorySecondSlideMobileRu]
};

const DEFAULT_LOCALE = 'en';

export default {
  name: 'OnlineConciergeService',
  components: { AdditionalInfoSection, OrderedList, UserGuideTabs, Slider },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      webshopDesktopActiveSlide: 0,
      webshopMobileActiveSlide: 0,
      treatmentActiveSlide: 0
    };
  },
  computed: {
    webshopDesktopImages() {
      const locale = this.locale === 'ru' ? DEFAULT_LOCALE : this.locale;

      return WEBSHOP_IMAGES_DESKTOP[locale];
    },
    webshopMobileImages() {
      const locale = this.locale === 'ru' ? DEFAULT_LOCALE : this.locale;

      return WEBSHOP_IMAGES_MOBILE[locale];
    },
    treatmentsHistoryImages() {
      return TREATMENTS_HISTORY_MOBILE_IMAGES[this.locale];
    },
    treatmentsHistoryDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/concierge-service/treatments-history-desktop-${this.locale}.jpg`);
    },
    consultationFeeDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/concierge-service/consultation-fee-desktop-${this.locale}.jpg`);
    },
    consultationFeeMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/concierge-service/consultation-fee-mobile-${this.locale}.jpg`);
    },
    sendOfferButtonDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/concierge-service/send-offer-button-desktop-${this.locale}.jpg`);
    },
    sendOfferButtonMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/concierge-service/send-offer-button-mobile-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/user-guide/article-shared';

.carousel {
  margin-top: 25px;
  text-align: center;

  &__point {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: $azure;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &--current {
      background-color: $orange-color;
    }

    &--previous {
      background-color: $dim-white;
    }
  }
}
</style>
