<template>
  <article class="dashboard-guide article">
    <section class="article__subsection">
      <div class="subsection__content">
        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="dashboardMainDesktopImage"
              alt="Dashboard"
              class="dashboard-guide__dashboard-main-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="dashboardMainMobileImage"
              alt="Dashboard"
              class="user-guide-tabs__image-mob"
            />
          </div>
        </user-guide-tabs>

        <div class="subsection__text">
          {{ $t('userGuide.dashboard.isMainPage') }}
          <br />
          {{ $t('userGuide.dashboard.typeOfOperationalPanel') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="headerSection" class="subsection__title">
        {{ $t('userGuide.title.headerSection') }}
      </div>

      <div class="subsection__content">
        <div class="subsection__text">
          {{ $t('userGuide.dashboard.headerSection') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="logo" class="subsection__subtitle">
          <span class="mr3">
            {{ $t('userGuide.title.logo') }}
          </span>
          <img
            src="@/assets/images/dashboard/user-guide/dashboard/skinXsLogoSmall.png"
            alt="SkinXs logo"
          />
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.dashboard.byClickingSkinxsLogo') }}
        </div>

        <div id="notificationsBell" class="subsection__subtitle">
          <span class="mr15">
            {{ $t('userGuide.title.notificationsBell') }}
          </span>
          <span
            class="custom-icon icon-notifications-bell inline-block vertical-align-middle cursor-default"
          ></span>
        </div>
        <div class="subsection__inner-text">
          <i18n path="userGuide.dashboard.hereAreLatestSystemNotifications" tag="span">
            <router-link class="subsection__link" :to="$options.dashboardPageLinks.NOTIFICATIONS">
              <b>{{ $t('userGuide.dashboard.label.systemNotifications') }}</b>
            </router-link>
          </i18n>
          <br />
          {{ $t('userGuide.dashboard.typesOfNotifications') }}
          <br />
          - {{ $t('userGuide.dashboard.newQuestionnaire') }}
          <br />
          - {{ $t('userGuide.dashboard.orderUnderPreparation') }}
          <br />
          - {{ $t('userGuide.dashboard.treatmentCompleted') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.dashboard.byClickingNotificationsBell') }}
        </div>

        <div id="burgerMenu" class="subsection__subtitle">
          <span class="mr10">{{ $t('userGuide.title.burgerMenu') }}</span>
          <span class="burger-menu-guide-icon fas fa-bars subsection__text-icon"></span>
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.dashboard.useBurgerMenu') }}
        </div>
      </div>
    </section>

    <section class="article__subsection">
      <div id="featuredFunctionsSection" class="subsection__title">
        {{ $t('userGuide.title.featuredFunctionsSection') }}
      </div>
      <div class="subsection__content mt15">
        <div class="dashboard-buttons-list flex--align-center space-between mb15">
          <img
            :src="createTreatmentButtonImage"
            class="dashboard-buttons-list__button"
            alt="Create treatment button"
          />
          <img
            :src="invitePatientsButtonImage"
            class="dashboard-buttons-list__button"
            alt="Invite patients button"
          />
          <img
            :src="quickLabelPrintImage"
            class="dashboard-buttons-list__button"
            alt="Quick label print button"
          />
        </div>

        <div class="subsection__text">
          {{ $t('userGuide.dashboard.featuredFunctions') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="createTreatment" class="subsection__subtitle">
          {{ $t('userGuide.title.createTreatment') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.canCreateTreatmentByUsingOptions') }}
          <br />
          <i18n path="userGuide.dashboard.makesItPossible" tag="span">
            <b>— {{ $t('userGuide.dashboard.label.treatmentWithPhotoAnalysis') }}</b>
          </i18n>
          <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15 mt15">
            <span slot="info-text">
              {{ $t('userGuide.dashboard.note.allYouNeed') }}
            </span>
          </additional-info-section>
          <i18n path="userGuide.dashboard.usingPreMadeFormulas" tag="span">
            <b>— {{ $t('userGuide.dashboard.label.treatmentFromDirectory') }}</b>
          </i18n>
          <br />
          <i18n path="userGuide.dashboard.suggestsYouToCreate" tag="span">
            <b>— {{ $t('userGuide.dashboard.label.treatmentFromScratch') }}</b>
          </i18n>
        </div>

        <div id="invitePatients" class="subsection__subtitle">
          {{ $t('userGuide.title.invitePatients') }}
        </div>

        <i18n path="userGuide.dashboard.sendInvitationsToYourPatients" tag="span">
          <router-link class="subsection__link" :to="$options.dashboardPageLinks.INVITE_PATIENTS">
            <b>{{ $t('userGuide.dashboard.learnMore') }}</b>
          </router-link>
        </i18n>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.remember.copyPersonalizedLink') }}
          </span>
        </additional-info-section>

        <div id="quickLabelPrint" class="subsection__subtitle">
          {{ $t('userGuide.title.quickLabelPrint') }}
        </div>

        <i18n path="userGuide.dashboard.youCanQuicklyFind" tag="span">
          <router-link class="subsection__link" :to="$options.dashboardPageLinks.QUICK_LABEL_PRINT">
            <b>{{ $t('userGuide.dashboard.learnMore') }}</b>
          </router-link>
        </i18n>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20 mt15">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.note.treatmentsOlder') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="patientsList" class="subsection__title">
        {{ $t('userGuide.title.patientsList') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.containsAllYourPatients') }}
        </div>

        <user-guide-tabs class="mb20">
          <div slot="desktop-content">
            <img
              :src="recordPatientDesktopImage"
              alt="Patient record"
              class="dashboard-guide__patient-record-desktop-image user-guide-tabs__image"
            />
          </div>
          <div slot="mobile-content">
            <img
              :src="recordPatientMobileImage"
              alt="Patient record"
              class="dashboard-guide__patient-record-mobile-image user-guide-tabs__image"
            />
          </div>
        </user-guide-tabs>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="actionMenu" class="subsection__subtitle">
          {{ $t('userGuide.title.actionMenu') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.givesYouSet') }}
          <br />
          - {{ $t('userGuide.dashboard.contactByEmail') }}
          <br />
          - {{ $t('userGuide.dashboard.contactByPhone') }}
          <br />
          - {{ $t('userGuide.dashboard.markPatientInactive') }}
          <br />
          - {{ $t('userGuide.dashboard.deletePatientRecord') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.tip.markMightBeUseful') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.note.patientsAreAutomatically') }}
          </span>
        </additional-info-section>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="lastComment" class="subsection__subtitle">
          {{ $t('userGuide.title.lastComment') }}
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.lastCommentIcon') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.note.lastCommentIcon') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="statuses" class="subsection__title">
        {{ $t('userGuide.title.statuses') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.patientsStatuses') }}
        </div>

        <status-list :status-list="$options.patientStatusList" class="mb15">
          <template slot="status" slot-scope="{ status }">
            <div>
              <div class="flex justify-content--center">
                <span class="patient-status-list__icon flex justify-content--center">
                  <span class="custom-icon" :class="status.icon"></span>
                </span>
                <span class="patient-status-list__description">
                  <i18n :path="`userGuide.dashboard.${status.descriptionKeyPostfix}`" tag="span">
                    <b>{{ $t(`userGuide.dashboard.label.${status.titleKeyPostfix}`) }}</b>
                  </i18n>
                </span>
              </div>

              <div v-if="status.titleKeyPostfix === 'inactive'">
                <additional-info-section
                  :info-type="$options.additionalInfoType.NOTE"
                  class="mb10 mt10"
                >
                  <span slot="info-text">
                    {{ $t('userGuide.dashboard.note.markInactiveOption') }}
                  </span>
                </additional-info-section>

                <additional-info-section
                  :info-type="$options.additionalInfoType.REMEMBER"
                  class="mb20"
                >
                  <span slot="info-text">
                    {{ $t('userGuide.dashboard.remember.patientsWithoutTreatment') }}
                  </span>
                </additional-info-section>
              </div>
            </div>
          </template>
        </status-list>
      </div>
    </section>

    <section class="article__subsection">
      <div id="searchFilterSection" class="subsection__title">
        {{ $t('userGuide.title.searchFilterSection') }}
      </div>
      <div class="subsection__content subsection__text">
        {{ $t('userGuide.dashboard.accessingRightPatient') }}
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="search" class="subsection__subtitle">
          <span class="mr5">{{ $t('userGuide.title.search') }}</span>
          <span class="custom-icon icon-search inline-block vertical-align-middle"></span>
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.searchTool') }}
        </div>

        <ordered-list class="mb20">
          <template slot="list-items">
            <li>{{ $t('userGuide.dashboard.clickSearchIcon') }}</li>
            <li>{{ $t('userGuide.dashboard.typeName') }}</li>
            <li>{{ $t('userGuide.dashboard.searchResults') }}</li>
          </template>
        </ordered-list>

        <div id="filter" class="subsection__subtitle">
          <span class="mr5">{{ $t('userGuide.title.filter') }}</span>
          <span class="custom-icon icon-filter inline-block vertical-align-middle"></span>
        </div>
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.helpYouSearch') }}
          <br />
          {{ $t('userGuide.dashboard.theFilterTool') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.TIP" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.tip.useTheSearch') }}
          </span>
        </additional-info-section>

        <additional-info-section :info-type="$options.additionalInfoType.REMEMBER" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.remember.filterResults') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="sorting" class="subsection__title">
        {{ $t('userGuide.title.sorting') }}
      </div>
      <div class="subsection__content subsection__text">
        {{ $t('userGuide.dashboard.patientsList') }}
      </div>
    </section>

    <section class="article__subsection">
      <div id="quickAccessTool" class="subsection__title">
        {{ $t('userGuide.title.quickAccessTool') }}
      </div>
      <div class="subsection__content">
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.useSidebar') }}
        </div>

        <user-guide-tabs class="mb20" is-one-option-only>
          <div slot="tabs">
            <span class="dashboard-guide-sidebar-tab">
              {{ $t('userGuide.dashboard.desktopOnly') }}
            </span>
          </div>
          <div slot="desktop-content">
            <img
              :src="dashboardSidebarImage"
              alt="Create treatment tabs"
              class="dashboard-guide__dashboard-sidebar-desktop-image user-guide-tabs__image"
            />
          </div>
        </user-guide-tabs>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb15">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.note.havePatient') }}
          </span>
        </additional-info-section>

        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.patientsAreSorted') }}
        </div>

        <additional-info-section :info-type="$options.additionalInfoType.NOTE" class="mb20">
          <span slot="info-text">
            {{ $t('userGuide.dashboard.note.haveNoPatients') }}
          </span>
        </additional-info-section>
      </div>
    </section>

    <section class="article__subsection">
      <div id="onlineSupportChat" class="subsection__title">
        {{ $t('userGuide.title.onlineSupportChat') }}
      </div>
      <div class="subsection__content">
        <img
          src="@/assets/images/dashboard/user-guide/dashboard/online-support-chat.png"
          alt="Online support chat icon"
        />
        <div class="subsection__inner-text">
          {{ $t('userGuide.dashboard.universkinSupportChat') }}
        </div>
      </div>

      <div class="subsection__content subsection__content--with-indent">
        <div id="howItWorks" class="subsection__subtitle">
          {{ $t('userGuide.title.howItWorks') }}
        </div>
        <div class="subsection__text">
          {{ $t('userGuide.dashboard.youCanSimplyClick') }}
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import ArticleSectionRoute from '@/modules/dashboard/components/user-guide/articles/ArticleSectionRoute';
import Status from '@/modules/dashboard/components/user-guide/Status';

import UserGuideTabs from '@/modules/dashboard/components/user-guide/UserGuideTabs';
import AdditionalInfoSection from '@/modules/dashboard/components/common/AdditionalInfoSection';
import StatusList from '@/modules/dashboard/components/user-guide/StatusList';
import OrderedList from '@/modules/dashboard/components/user-guide/OrderedList';

import { ADDITIONAL_INFO_TYPE } from '@/modules/dashboard/api/constants';
import { USER_GUIDE_ARTICLE_NAME } from '@/modules/dashboard/api/user-guide';

const PATIENT_STATUS_LIST = [
  new Status('icon-clipboard', 'newQuestionnaire', 'thePatientCompletedQuestionnaire'),
  new Status('icon-stopwatch-solid', 'treatmentStartsIn', 'treatmentWasCreated'),
  new Status('icon-started-gray', 'ongoingTreatment', 'treatmentCreatedStarted'),
  new Status('icon-expired', 'treatmentCompleted', 'treatmentExpired'),
  new Status('icon-inactive', 'inactive', 'changeToInactive'),
  new Status('icon-offer-sent', 'offerSent', 'treatmentOfferWasSent'),
  new Status('icon-under-preparation', 'orderUnderDelivery', 'patientPaidTreatmentOffer')
];

const DASHBOARD_PAGE_LINKS = {
  QUICK_LABEL_PRINT: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT
  }),
  INVITE_PATIENTS: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS
  }),
  NOTIFICATIONS: new ArticleSectionRoute({
    articleName: USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS
  })
};

export default {
  name: 'DashboardGuide',
  components: { OrderedList, StatusList, AdditionalInfoSection, UserGuideTabs },
  additionalInfoType: ADDITIONAL_INFO_TYPE,
  patientStatusList: PATIENT_STATUS_LIST,
  dashboardPageLinks: DASHBOARD_PAGE_LINKS,
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    dashboardMainDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/dashboard-main-desktop-${this.locale}.jpg`);
    },
    dashboardMainMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/dashboard-main-mobile-${this.locale}.jpg`);
    },
    createTreatmentButtonImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/create-treatment-button-${this.locale}.png`);
    },
    invitePatientsButtonImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/invite-patients-button-${this.locale}.png`);
    },
    quickLabelPrintImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/quick-label-print-button-${this.locale}.png`);
    },
    recordPatientDesktopImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/patient-record-desktop-${this.locale}.jpg`);
    },
    recordPatientMobileImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/patient-record-mobile-${this.locale}.jpg`);
    },
    dashboardSidebarImage() {
      return require(`@/assets/images/dashboard/user-guide/dashboard/dashboard-sidebar-desktop-${this.locale}.jpg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/user-guide/article-shared';

.burger-menu-guide-icon {
  color: $text-color;
}

.dashboard-buttons-list__button {
  width: 200px;
}

.dashboard-guide {
  &__dashboard-main-desktop-image {
    min-height: 427px;
  }

  &__patient-record-desktop-image {
    min-height: 142px;
  }

  &__patient-record-mobile {
    width: 450px;
  }

  &__dashboard-sidebar-desktop-image {
    min-height: 284px;
  }
}

@media (max-width: 1023px) {
  .dashboard-guide {
    &__dashboard-main-desktop-image {
      min-height: 265px;
    }

    &__patient-record-desktop-image {
      min-height: 88px;
    }

    &__dashboard-sidebar-desktop-image {
      min-height: 176px;
    }
  }
}

@media (max-width: 767px) {
  .dashboard-guide {
    &__dashboard-main-desktop-image {
      min-height: auto;
    }

    &__patient-record-desktop-image {
      min-height: auto;
    }

    &__patient-record-mobile-image {
      min-height: 79px;
    }

    &__dashboard-sidebar-desktop-image {
      min-height: auto;
    }
  }
}

.patient-status-list {
  &__icon {
    width: 25px;
    flex-shrink: 0;

    align-self: flex-start;
  }

  &__description {
    display: block;
    font-size: 13px;
    line-height: 16px;

    color: $text-color;
  }
}

.dashboard-guide-sidebar-tab {
  padding-bottom: 5px;
  font-style: italic;

  cursor: default;
}

@media (max-width: 1023px) {
  .dashboard-buttons-list {
    flex-direction: column;
  }
}
</style>
